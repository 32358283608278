<template>
  <div class="sales-index">
    <VueSlickCarousel :arrows="true" :dots="true">
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">Выгодные проездные и оплата телефоном</p>
          <p class="sales-index__subtitle">Виртуальная транспортная карта для удобной и выгодной оплаты проезда в общественном транспорте</p>
          <a href="https://play.google.com/store/apps/details?id=ru.novacard.siticard&hl=ru&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" class="sales-index__btn btn">Подробнее</a>
        </div>
        <img src="@/assets/img/slide-1.png" alt="" class="sales-index__pic">
      </div>
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">Узнайте баланс вашей транспортной карты</p>
          <p class="sales-index__subtitle">История о балансе обновляется не реже одного раза в сутки</p>
          <div @click="open_service('#refill')" class="sales-index__btn btn">Подробнее</div>
        </div>
        <img src="@/assets/img/slide-3.png" alt="" class="sales-index__pic">
      </div>
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">Еще удобнее через приложение Ситикард</p>
          <p class="sales-index__subtitle">Пополняйте через приложение Ситикард на Android</p>
          <a href="https://play.google.com/store/apps/details?id=ru.novacard.siticard&hl=ru&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" class="sales-index__btn btn">Подробнее</a>
        </div>
        <img src="@/assets/img/slide-2.png" alt="" class="sales-index__pic">
      </div>
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">История поездок по транспортной карте</p>
          <p class="sales-index__subtitle">Информация обновляется не реже одного раза в сутки</p>
          <div @click="open_service('#history')" class="sales-index__btn btn">Подробнее</div>
        </div>
        <img src="@/assets/img/slide-4.png" alt="" class="sales-index__pic">
      </div>
    </VueSlickCarousel>
      <popup :view="showOpenService" @close="close_popup">
        <div class="edit-data" v-if="cards == null">
            <div class="edit-data__sign">Упс. У вас нет добавленных транспортных карт.</div>
            <div class="edit-data__center">
              <div class="edit-data__btn btn" @click="add_card">Добавить карту</div>
            </div>
        </div>
        <div class="edit-data" v-else>
          <p class="edit-data__holder">Выберите карту</p>
          <div class="edit-data__txt">Для изменения электронной почты <br> нужно ввести код подтверждения</div>
          <div class="edit-data__select-card">
            <multiselect v-model="cardlink"  :options="cards" :searchable="false" :preselectFirst="true"
              :show-labels="false" placeholder="Выберете карту" track-by="number"  label="name">
              <template slot="singleLabel" slot-scope="props">
                <span class="option-c">
                  <span :class="'color-' + props.option.color"></span>
                  <span class="option-c__title">{{ props.option.name }}</span>
                  <span class="option-c__numb">{{ props.option.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-c">
                  <span :class="'color-' + props.option.color"></span>
                  <span class="option-c__title">{{ props.option.name }}</span>
                  <span class="option-c__numb">{{ props.option.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</span>
                </div>
              </template>
            </multiselect>
          </div>
          <button class="edit-data__save btn" :disabled="cardlink == null" @click="event_link">Продолжить</button>
        </div>
      </popup>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import Multiselect from 'vue-multiselect'

import popup from '@/layout/popup'

import { Base64 } from 'js-base64'
import { mapGetters } from 'vuex'

export default {
  name: 'SalesIndex',
  data() {
    return {
      cardlink: null,
      flag: null,
      showOpenService: false,
    }
  },
  methods: {
    open_service(e) {
      this.flag = e;
      this.showOpenService = true;
    },
    event_link() {
      if (this.cardlink != null) {
        this.$router.push('/mycards/' + this.encoder(this.cardlink.number) + this.flag);
        document.querySelector('body').removeAttribute('style');
      }
    },
    encoder(number) {
      return Base64.encode(number)
    },
    add_card() {
      this.showOpenService = false;
      this.$parent.$children[1].open_add_card_popup();
    },
    close_popup() {
      this.showOpenService = false;
      this.flag = null;
      document.querySelector('body').removeAttribute('style');
    }
  },
  computed: {
    ...mapGetters(['cards']),
  },
  components: {
    VueSlickCarousel,
    Multiselect,
    popup
  },
}
</script>
