<template>
  <baseLayout>
    <div class="content__contain first">
      <div class="content__head">
        <a href="/" class="content__back">Главное</a>
        <h1 class="content__title">Новости</h1>
      </div>
      <div class="content__list">
        <router-link class="news-item" v-for="item in list" :key="item.id" :to="'/news/' + item.id" >
          <!-- <img src="@/assets/img/news-1.jpg" alt="" class="news-item__pic"> -->
          <div class="news-item__content">
            <p class="news-item__date">{{  currentDate(item.created, 'L') }}</p>
            <p class="news-item__title">{{ item.title }}</p>
            <!-- <div class="news-item__txt" v-html="item.description.slice(0, item.description.indexOf(' ', 200))"></div> -->
          </div>
        </router-link>
      </div>
      <button class="content__more" @click="more_news" v-show="showButton">Показать еще</button>
    </div>
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'
import { mapState } from 'vuex'

export default {
  name: 'News',
  data() {
    return {
      list: [],
      count: 10,
      showButton: true,
    }
  },
  mounted(){
    if (this.newsList.length < 20) this.$store.dispatch('get_news_list', this.newsList.length + 10)
    this.list = this.newsList.slice(0, this.count)
  },
  methods: {
    more_news(){
      this.count+=10;
      this.list = this.newsList.slice(0, this.count);
      this.$store.dispatch('get_news_list', this.newsList.length + 10);
      if(this.newsList.length < this.count){
        this.showButton = false;
        return;
      }
    }
  },
  components: {
    baseLayout,
  },
  computed: {
    ...mapState(['newsList']),
  },
}
</script>
