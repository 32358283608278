<template>
  <div class="faq-banner">
    <div class="faq-banner__info">
      <p class="faq-banner__title">Помощь</p>
      <p class="faq-banner__subtitle">Опишите возникшую у вас проблему в обращении, мы поможем быстро найти решение</p>
      <div class="faq-banner__control">
        <a href="/support" class="faq-banner__btn btn">Перейти</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQIndex',
  methods: {
  },
}
</script>
