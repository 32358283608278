<template>
  <div class="outer">
    <heading />
    <div class="content">
      <div class="content__container inner">
          <div class="content__contain first">
            <div class="content__head">
              <a href="/" class="content__back">Главное</a>
              <h1 class="content__title">Пункты обслуживания - <span>Нижний Новгород</span></h1>
            </div>
            <div class="service-point">
              <div class="service-point__txt">Здесь вы найдете подробную информацию о пунктах обслуживания "Ситикард" и пунктах наших партнеров, где можно оформить, получить или пополнить транспортную карту.</div>
            </div>
          </div>
        </div>
      </div>
      <foot />
  </div>
</template>

<script>

import heading from '@/components/core/header.vue'
import foot from '@/components/core/footer.vue'

export default {
  name: 'Service_point',
  methods: {
  },
  components: {
    heading,
    foot,
  },
  mounted(){
    this.$store.dispatch('get_map_id');
  }
}
</script>
