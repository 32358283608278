<template>
  <div class="system-error" v-if="appError == 2 || appError == 3 || appError == 6 || appError == 500 || appError == 503 || appError == 403">
    <transition name="viewError" mode="out-in">
    <div class="system-error__box" v-if="appError != 'failed'">
        <p v-if="appError == 2">Необходимо обновить приложение.</p>
        <p v-if="appError == 3">Доступ запрещен. Обратитесь в службу поддержки клиентов.</p>
        <p v-if="appError == 6">Срок действия сессии истек. Перезагрузите страницу</p>
        <p v-if="appError == 403">Срок действия сессии истек. Страница перезагрузится через 5 секунд</p>
        <p v-if="appError == 500">Произошла ошибка, и мы уже работаем над ее устранением. Попробуйте позже.</p>
        <p v-if="appError == 503">Сервис на техническом обслуживании. Попробуйте позже.</p>
      </div>
    </transition>
    <div class="system-over"></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'System_error',
    updated(){
      if(this.appError == 2 || this.appError == 3 ||  this.appError == 6 || this.appError == 500 || this.appError == 503 || this.appError == 403) {
        window.scrollTo(0, 0);
        document.querySelector('body').style.overflow = 'hidden'
      } else{
        document.querySelector('body').removeAttribute('style');
      }
    },
    computed: {
      ...mapState(['appError']),
    }
  }
</script>


<style lang="sass">
.system-error__box
  background: #FF4224
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  font-size: 22px
  font-weight: 600
  height: 66px
  position: relative
  z-index: 999992
  @media screen and (max-width: 1200px)
    font-size: 18px
    height: 50px

.viewError-enter-active,
.viewError-leave-active
  transition: all 1s
  height: 66px
  @media screen and (max-width: 1200px)
    height: 50px

.viewError-enter,
.viewError-leave-to
  opacity: 0
  height: 0px
.system-over
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  z-index: 9999
  background: rgba(255, 255, 255, 0.1)
  backdrop-filter: blur(2px)
</style>