<template>
  <div class="service-breed">
    <div class="service-breed__head">
      <p class="service-breed__title">Сервисы</p>
      <a href="/all-service" class="service-breed__more">Все</a>
    </div>
    <div class="service-breed__list">
      <div class="service-breed__item" @click="open_service">
        <img src="@/assets/icon/icon-service-1.svg" alt="Пополнить карту" class="service-breed__pic">
        <p class="service-breed__caption">Пополнить карту или сменить тариф</p>
      </div>
      <a class="service-breed__item" href="/all-service/apply-for-card/">
        <img src="@/assets/icon/icon-service-5.svg" alt="Оформить транспортную карту" class="service-breed__pic">
        <p class="service-breed__caption">Оформить <br> транспортную карту</p>
      </a>
      <a class="service-breed__item" href="/all-service/check">
        <img src="@/assets/icon/icon-service-6.svg" alt="Проверка готовности транспортной карты"
          class="service-breed__pic">
        <p class="service-breed__caption">Проверить готовность карты</p>
      </a>
      <a class="service-breed__item" href="/all-service/extend">
        <img src="@/assets/icon/icon-service-4.svg" alt="Продлить карту студента/школьника" class="service-breed__pic">
        <p class="service-breed__caption">Продлить карту <br> студента или школьника</p>
      </a>
    </div>
    <popup :view="showOpenService" @close="close_popup">
      <div class="edit-data" v-if="cards == 7">
          <div class="edit-data__sign">Упс. У вас нет добавленных транспортных карт.</div>
          <div class="edit-data__center">
            <div class="edit-data__btn btn" @click="add_card">Добавить карту</div>
          </div>
      </div>
      <div class="edit-data" v-else>
        <p class="edit-data__holder">Выберите карту</p>
        <div class="edit-data__txt">Чтобы проверить баланс <br> или просмотреть историю поездок</div>
        <div class="edit-data__select-card">
          <multiselect v-model="cardlink"  :options="cards" :searchable="false" :preselectFirst="true"
            :show-labels="false" placeholder="Выберете карту" track-by="number"  label="name" @select="select">
            <template slot="singleLabel" slot-scope="props">
              <span class="option-c">
                <span :class="'color-' + props.option.color"></span>
                <span class="option-c__title">{{ props.option.name != undefined ? props.option.name : 'Транспортная карта' }}</span>
                <span class="option-c__numb">{{ props.option.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option-c">
                <span :class="'color-' + props.option.color"></span>
                <span class="option-c__title">{{ props.option.name != undefined ? props.option.name : 'Транспортная карта' }}</span>
                <span class="option-c__numb">{{ props.option.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <button class="edit-data__save btn" :disabled="cardlink == null" @click="event_link">Продолжить</button>
      </div>
    </popup>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import Multiselect from 'vue-multiselect'

import popup from '@/layout/popup'

import { Base64 } from 'js-base64'

export default {
  name: 'ServiceIndex',
  data() {
    return {
      cardlink: null,
      flag: null,
      showOpenService: false,
    }
  },
  methods: {
    open_service(){
      this.showOpenService = true;
    },
    event_link() {
      if (this.cardlink != null) {
        this.$router.push('/mycards/' + this.encoder(this.cardlink.number));
        document.querySelector('body').removeAttribute('style');
      }
    },
    encoder(number) {
      return Base64.encode(number)
    },
    add_card(){
      this.showOpenService = false;
      this.$parent.$children[1].open_add_card_popup();
    },
    close_popup() {
      this.showOpenService = false;
      document.querySelector('body').removeAttribute('style');
    },
    select()	{
      document.querySelector('.multiselect__content-wrapper').scrollTo(0, 0)
    }
  },
  computed: {
    ...mapGetters(['cards']),
  },
  components: {
    Multiselect,
    popup
  }
}
</script>
