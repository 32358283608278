<template>
  <baseLayout>
    <div class="content__contain first">
      <div class="content__head">
        <router-link to="/all-service" class="content__back">Сервисы</router-link>
        <h1 class="content__title">Оформление транспортной карты</h1>
      </div>
      <ul class="reg-steps" v-if="stateTab > 1">
        <li class="reg-steps__item" :class="{ active: stateTab > 1 }"></li>
        <li class="reg-steps__item" :class="{ active: stateTab > 2 }"></li>
        <li class="reg-steps__item" :class="{ active: stateTab > 3 }"></li>
        <li class="reg-steps__item" :class="{ active: stateTab > 4 }"></li>
      </ul>
      <form class="svc" data-status="ptk" @submit.prevent="sendForm" enctype="multipart/form-data">
        <!--               step 1-->
        <div class="svc__box" v-if="stateTab == 0">
          <div class="release">
            <div class="release__text">
              <p>Здесь вы можете оформить заявку на выпуск персональной транспортной карты для оплаты проезда по
                гражданским (не льготным) тарифам в пассажирском транспорте Нижегородской области. При подачи заявки вам
                потребуются загрузить фотографию пользователя карты и изображения нескольких документов.</p>
              <p>Фотография должна отвечать следующим требованиям:</p>
              <p>Гражданин на фотографии должен соответствовать своему возрасту на момент подачи заявления. Лицо — анфас,
                смотреть нужно прямо на камеру, ничего не должно закрывать лица. Выражение лица — нейтральное, закрытый
                рот и открытые глаза.</p>
              <p>Овал лица должен занимать 3/4 от размера фотографии. Фотография должна быть с соотношением высоты и
                ширины 4:3 и выполнена на белом фоне. Фон должен быть без полос, пятен, теней и изображения посторонних
                предметов.</p>
            </div>
            <div class="release__gray-box">
              <h5>Список необходимых документов:</h5>
              <ul>
                <li>Основной разворот паспорта гражданина РФ </li>
                <li>Страховое свидетельства государственного пенсионного страхования (СНИЛС)</li>
              </ul>
            </div>
            <div class="release__control">
              <div class="release__text">
                <p>Файлы документов, указанных в форме, должны быть только в формате jpeg.</p>
              </div>
              <div class="btn" @click.prevent='stepNext()'> Начать оформление заявки </div>
            </div>
          </div>
        </div>
        <!--               step 1-->
        <!--               step 2-->
        <div class="svc__box" v-show="stateTab == 1">
          <div class="register-card callback" data-tab="1" ref="tab-1">
            <div class="register-card__wrapper">
              <div class="register-card__form">
                <div class="callback__group"><span class="callback__name">№ СНИЛС</span>
                  <input type="text" name="fld_368" @blur="checkSnils" @focus="focus" v-model='dataForm.fld_368' inputmode="numeric"
                    class="callback__field required" v-mask="'###-###-### ##'" />
                </div>
                <div class="callback__group">
                  <span class="callback__name">Дата рождения</span>
                  <datepicker format="dd.MM.yyyy" :language="language" :disabled-dates="disabledDates" v-model='date_of_birth'
                    name="date_of_birth" ref="date_birth" @input="checkDate" autocomplete="off"
                    class="callback__date">
                  </datepicker>
                </div>
                <div class="system-warning" v-if="isSchool">
                  <p>Учащемуся старше 7 лет для льготного проезда на пассажирском транспорте необходимо оформить Карту
                      жителя для школьника. <br>  <br> <a href="//siticard.ru/kgno/">Оформить заявку</a></p>
                </div>
                <div class="callback__group focus">
                    <span class="callback__name">E-mail</span>
                    <input type="text" name="fld_424" @focus="focus" @blur="blur" inputmode="email" v-model='dataForm.fld_424'
                      autocomplete="off" class="callback__field required" />
                  </div>
                <label class="callback__checkbox">
                  <input type="checkbox" @change="statusCheckPolicy = !statusCheckPolicy" />
                  <span>
                    Я подтверждаю, что ознакомился с документом <a href="/user_agreement/"
                      target="_blank">«Пользовательское
                      соглашение» </a> и согласен на передачу информации в электронной форме заявления (в том числе
                    персональных данных) по открытым каналам связи сети Интернет.
                  </span>
                </label>
                <div class="btn" @click.prevent='stepNext()' :class="{ disabled: isSchool }">Продолжить</div>

                <p class="tooltyp-text-mobile">Оформление карт льготника, студента и школьника для льготной оплаты проезда
                  доступно в
                  <a href="/kgno/" target="_blank">Сервисе Карты жителя</a>
                </p>
              </div>
            <div class="register-card__tooltip">
              <p><b>Здесь можно оформить транспортную карту Ситикард.</b></p>
              <div class="register-card__card"><img src="@/assets/icon/icon-card-cevil.svg"
                  alt="Персональная транспортная карта" /><span>Персональная транспортная карта</span></div>
              <p>Оформление карт льготника, студента и школьника для льготной оплаты проезда доступно в
                <a href="/kgno/" target="_blank">Сервисе Карты жителя</a>
              </p>
            </div>
            </div>
          </div>

        </div>
        <!--               step 2-->
        <!--               step 3-->
        <div class="svc__box" v-show="stateTab > 1">
          <div class="register-card callback" data-tab="2">
            <div class="register-card__head" :class="{ active: stateTab == 2, visible: stateTab > 2 }">
              <span class="register-card__numb">Шаг 1.</span>
              <div class="register-card__icon"></div>
              <span class="register-card__title">Персональные данные</span>
            </div>
            <div class="register-card__container" v-show="stateTab == 2">
              <div class="register-card__cols">
                <div class="register-card__col">
                  <h4 class="callback__cap" >Личные данные владельца карты</h4>
                  <div class="callback__group"><span class="callback__name">Фамилия</span>
                    <input type="text" name="last_name" v-model="dataForm.last_name" @focus="focus" @blur="blur" @keyup="typeText" @input="typeText"
                      autocomplete="off" class="callback__field required">
                  </div>
                  <div class="callback__group"><span class="callback__name">Имя</span>
                    <input type="text" name="first_name" v-model="dataForm.first_name" @focus="focus" @blur="blur" @keyup="typeText" @input="typeText"
                      autocomplete="off" class="callback__field required">
                  </div>
                  <div class="callback__group"><span class="callback__name">Отчество</span>
                    <input type="text" name="second_name" v-model="dataForm.second_name" @focus="focus" @blur="blur" @keyup="typeText"
                      @input="typeText" autocomplete="off" class="callback__field required">
                  </div>
                  <div class="callback__group"><span class="callback__name">Номер телефона</span>
                    <input type="tel" name="fld_369" v-model="dataForm.fld_369" v-mask="'+7-###-###-##-##'"
                      inputmode="tel" @blur="checkLengthPhone" @focus="focus" autocomplete="off" class="callback__field required" />
                  </div>
                    <div class="callback__group"><span class="callback__name">Серия паспорта</span>
                      <input type="text" name="series_doc" v-model="dataForm.series_doc" @focus="focus" @blur="blur" v-mask="'####'" maxlength="4"
                        autocomplete="off" class="callback__field required" />
                    </div>
                    <div class="callback__group"><span class="callback__name">Номер паспорта</span>
                      <input type="text" name="num_doc" v-model="dataForm.num_doc" @focus="focus" @blur="blur" v-mask="'######'" inputmode="numeric"
                        autocomplete="off" class="callback__field required" />
                    </div>
                  <div class="callback__radio-over"><span class="callback__cap">Ваш пол</span>
                    <div class="callback__radio">
                      <label for="gender-man" class="radio">
                        <input type="radio" id="gender-man" checked @change="dataForm.id_ref_sex = 'male'"
                          name="id_ref_sex"><span>Мужской</span>
                      </label>
                      <label for="gender-women" class="radio">
                        <input type="radio" id="gender-women" name="id_ref_sex"
                          @change="dataForm.id_ref_sex = 'famale'"><span>Женский</span>
                      </label>
                    </div>
                  </div>
                  <div class="callback__group"><span class="callback__name">Номер домашнего телефона</span>
                    <input type="tel" name="fld_388" v-mask="'+7-###-###-##-##'" @blur="checkLengthPhone" @focus="focus" inputmode="tel"
                      v-model="dataForm.fld_388" autocomplete="off" class="callback__field">
                  </div>
                  <h4 class="callback__cap">Место выдачи карты</h4>
                    <multiselect :searchable="false" v-model="issuance" @input="pointCard" placeholder="Укажите город"
                      :options="listCity" track-by="ID" label="NAME" :show-labels="false"></multiselect>
                    <multiselect :searchable="false" v-model="pointPickup" placeholder="Выберите пункт выдачи карты"
                      :options="pointPickupList" track-by="ID" label="NAME" :show-labels="false"></multiselect>
                </div>
              </div>
              <div class="register-card__control">
                <div class="btn btn_color_gray " @click.prevent='stepPrev()'>Назад</div>
                <div class="btn" @click.prevent='stepNext()'>Продолжить</div>
              </div>
            </div>
          </div>
        </div>
        <!--               step 3-->
        <!--               step 5-->
        <div class="svc__box" v-show="stateTab > 1">
          <div class="register-card callback" data-tab="3">
            <div class="register-card__head" :class="{ active: stateTab == 3, visible: stateTab > 3 }">
              <span class="register-card__numb">Шаг 2.</span>
              <div class="register-card__icon"></div>
              <span class="register-card__title">Адреса</span>
            </div>
            <div class="register-card__container" v-show="stateTab == 3">
              <div class="register-card__cols">
                <div class="register-card__col" data-address="reg">
                  <h4 class="callback__cap">Адрес регистрации</h4>
                  <div class="callback__group"><span class="callback__name">Область</span>
                    <input type="text" name="r_obl" @blur="checkLengthRegAddr"  @focus="focus" autocomplete="off"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Район (при наличии)</span>
                    <input type="text" name="r_rn" @blur="checkLengthRegAddr" @focus="focus"  autocomplete="off"
                      class="callback__field" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Город</span>
                    <input type="text" name="r_ct" @blur="checkLengthRegAddr" @focus="focus"  autocomplete="off"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Населенный пункт </span>
                    <input type="text" name="r_np" @blur="checkLengthRegAddr" @focus="focus"  autocomplete="off"
                      class="callback__field " autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Улица</span>
                    <input type="text" name="r_str" @blur="checkLengthRegAddr" @focus="focus"  autocomplete="off"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Дом</span>
                    <input type="text" name="r_hs" @blur="checkLengthRegAddr" @focus="focus"  autocomplete="off" inputmode="numeric"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Корпус</span>
                    <input type="text" name="r_kp" autocomplete="off" @focus="focus" @blur="blur" class="callback__field" autocorrect="off"
                      autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Квартира</span>
                    <input type="text" name="r_kv" @blur="checkLengthRegAddr" @focus="focus"  autocomplete="off" @keypress="NumbersOnly"
                      inputmode="numeric" class="callback__field" autocorrect="off" autocapitalize="off"
                      spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Индекс</span>
                    <input type="text" name="r_ind" readonly="readonly" disabled autocomplete="off" inputmode="numeric"
                      class="callback__field" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>

                  <label class="callback__checkbox">
                    <input type="checkbox" name="fld_428" @click="copyCityData" :disabled="!cityCopy">
                    <span>Адрес фактического проживания совпадает с адресом регистрации</span>
                  </label>
                </div>
                <div class="register-card__col" data-address="fact">
                  <h4 class="callback__cap">Адрес проживания</h4>
                  <div class="callback__group"><span class="callback__name">Область</span>
                    <input type="text" name="f_obl" @blur="checkLengthFactAddr" @focus="focus"  autocomplete="off"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Район (при наличии)</span>
                    <input type="text" name="f_rn" @blur="checkLengthFactAddr" @focus="focus"  autocomplete="off"
                      class="callback__field" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Город</span>
                    <input type="text" name="f_ct" @blur="checkLengthFactAddr" @focus="focus"  autocomplete="off"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Населенный пункт (при
                      наличии)</span>
                    <input type="text" name="f_np" @blur="checkLengthFactAddr" @focus="focus"  autocomplete="off"
                      class="callback__field " autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Улица</span>
                    <input type="text" name="f_str" @blur="checkLengthFactAddr" @focus="focus"  autocomplete="off"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Дом</span>
                    <input type="text" name="f_hs" @blur="checkLengthFactAddr" @focus="focus"  autocomplete="off" inputmode="numeric"
                      class="callback__field required" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Корпус</span>
                    <input type="text" name="f_kp" autocomplete="off" @focus="focus" @blur="blur" class="callback__field" autocorrect="off"
                      autocapitalize="off" spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Квартира</span>
                    <input type="text" name="f_kv" @blur="checkLengthFactAddr" @focus="focus"  autocomplete="off" inputmode="numeric"
                      @keypress="NumbersOnly" class="callback__field" autocorrect="off" autocapitalize="off"
                      spellcheck="false">
                  </div>
                  <div class="callback__group"><span class="callback__name">Индекс</span>
                    <input type="text" name="f_ind" readonly="readonly" disabled autocomplete="off" inputmode="numeric"
                      class="callback__field" autocorrect="off" autocapitalize="off" spellcheck="false">
                  </div>
                </div>
              </div>
              <div class="register-card__control">
                <div class="btn btn_color_gray " @click.prevent='stepPrev()'>Назад</div>
                <div class="btn" @click.prevent='stepNext()'>Продолжить</div>
              </div>
            </div>
          </div>
        </div>
        <!--               step 5-->
        <!--               step 6-->
        <div class="svc__box" v-show="stateTab > 1">
          <div class="register-card callback" data-tab="4">
            <div class="register-card__head" :class="{ active: stateTab == 4, visible: stateTab > 4 }">
              <span class="register-card__numb">Шаг 3.</span>
              <div class="register-card__icon"></div>
              <span class="register-card__title">Документы</span>
            </div>
            <div class="register-card__container" v-show="stateTab == 4">
            <div class="release">
              <article class="release__text"><img src="@/assets/img/photo.png" alt="" class="pic">
                <p>Прикрепите, пожалуйста, в форме ниже фотографию владельца карты, отвечающую требованиям и изображения
                  указанных документов. Все изображения должны быть <b class="text-uppercase"> ТОЛЬКО В ФОРМАТЕ JPEG</b> .
                </p>
                <a href="#" @click.prevent="showTab = !showTab">См. требования к фотографии</a>
                <div class="release__gray-box" v-if="showTab" style="display: grid;">
                  <h5>Требования к фотографии владельца карты:</h5>
                  <p>Придерживайтесь следующих требований к фотографии пользователя карты</p>
                  <ul class="list">
                    <li>Гражданин на фотографии должен соответствовать своему возрасту на момент подачи заявления. Лицо —
                      анфас, смотреть нужно прямо на камеру, ни что не должно закрывать лицо. Выражение лица —
                      нейтральное, закрытый рот и открытые глаза.</li>
                    <li>Овал лица должен занимать 3/4 от размера фотографии. Фотография должна быть с соотношением высоты
                      и ширины 4:3 и выполнена на белом фоне. Фон должен быть без полос, пятен, теней и изображения
                      посторонних предметов. </li>
                  </ul>
                </div>
              </article>
            </div>
              <div class="register-card__files">
              <div class="register-card__file">
                <p class="callback__cap">Фотография на карту</p>
                <label class="callback__file field-file" ref="fileArea">
                  <input type="file" name="scn_fld_374" class="field-file__hidden" @change="addFile" accept="image/jpeg">
                  <div class="field-file__info">
                    <span class="field-file__name" ref="fileName" >Загрузить файл</span>
                    <div class="field-file__btn field-file__btn_white" @click="removeFile">
                      <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="8" width="18" height="2" rx="1" fill="#2196F3" />
                        <rect x="10" width="18" height="2" rx="1" transform="rotate(90 10 0)" fill="#2196F3" />
                      </svg>
                    </div>
                  </div>
                </label>
              </div>
              <div class="register-card__file">
                <p class="callback__cap">Основной разворот паспорта (свидетельства о рождении) пользователя
                    карты</p>
                <label class="callback__file field-file" ref="fileArea">
                  <input type="file" name="scn_fld_415" class="field-file__hidden" @change="addFile" accept="image/jpeg">
                  <div class="field-file__info">
                    <span class="field-file__name" ref="fileName" >Загрузить файл</span>
                    <div class="field-file__btn field-file__btn_white" @click="removeFile">
                      <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="8" width="18" height="2" rx="1" fill="#2196F3" />
                        <rect x="10" width="18" height="2" rx="1" transform="rotate(90 10 0)" fill="#2196F3" />
                      </svg>
                    </div>
                  </div>
                </label>
              </div>
              <div class="register-card__file">
                <p class="callback__cap">СНИЛС</p>
                <label class="callback__file field-file" ref="fileArea">
                  <input type="file" name="scn_fld_3" class="field-file__hidden" @change="addFile" accept="image/jpeg">
                  <div class="field-file__info">
                    <span class="field-file__name" ref="fileName" >СНИЛС</span>
                    <div class="field-file__btn field-file__btn_white" @click="removeFile">
                      <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="8" width="18" height="2" rx="1" fill="#2196F3" />
                        <rect x="10" width="18" height="2" rx="1" transform="rotate(90 10 0)" fill="#2196F3" />
                      </svg>
                    </div>
                  </div>
                </label>
              </div>
                <div class="register-card__control">
                  <div class="btn btn_color_gray" @click.prevent='stepPrev()'>Назад</div>
                  <button class="btn">Завершить и отправить</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--               step 6-->
      </form>
      <div class="call-message" v-if="stateTab == 'snilsFall'">
        <h4>Вы уже имеете карту Ситикард</h4>
        <article class="text">
          <p>К сожалению, повторно заказать карту невозможно, но мы всегда ждем Вас в наших пунктах выдачи карт, где Вы
            сможете заказать перевыпуск карты.</p>
          <p>Ближайший к Вам пункт выдачи карт и режим работы вы можете посмотреть <a
              href="/distribution-points/">здесь</a>.</p>
        </article>
      </div>
      <div class="call-message" v-if="stateTab == 'success'">
        <h4>Ваш запрос обработан</h4>
        <article class="text">
          <p>Вы оформили заявление на выпуск персональной транспортной карты на нашем сайте <a
              href="//siticard.ru">www.siticard.ru</a></p>
          <p>Ваше заявление принято в работу. После проверки данных Вам будет направлено подтверждение о том, что Ваше
            заявление передано в производство для выпуска карты.</p>
          <p>Спасибо, что воспользовались сервисом оформления персональных транспортных карт "Ситикард".</p>
        </article>
        <a href="/" class="btn">на Главную страницу</a>
      </div>
    </div>
  </baseLayout>
</template>

<script>

import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'

import {ru} from 'vuejs-datepicker/dist/locale'

import axios from "axios";

const api = '/service';

import baseLayout from '@/layout/base'
export default {
  name: 'PTK',
  data() {
    return {
      statusError: false, // Статус ошибки
      statusCheckPolicy: false, // Проверка нажатия чекбокса
      region: 52, // решион
      language: ru,
      year: '', // Возраст
      stateTab: 0, // Главные табы
      stateTabCard: 1, // табы на шаге 2
      allowedСards: [], //Какие карты можно
      classNumber: null, // Номер класса
      classCode: '', // Буква класса
      courseNumber: null, // Номер курса
      courseCode: null, // Код вуза
      codePref: null, // Выбранная льгота
      eduName: '', // Название учереждения обучения школьника
      vuzName: '', // Название учереждения обучения студента
      eduList: [],
      pointPickup: null, // Точка выдачи
      pointPickupList: [], //Список точек выдачи выдачи
      scan_files: [], //Файлы при выборе льготной категории
      scan_file: '', //Выбранные файлы льготы
      issuance: null, //Города выдачи
      checkCode: null, // Проверочный код
      checkCodeValue: null, // Значение проверочного кода
      showTab: false, // Показывать текст
      sendReqStatus: true,
      isSchool: false,
      listCity: [],
      disabledDates: { // Ограничения дат
        to: new Date(1900, 0, 1),
        from: new Date()
      },
      checkDateState: false,
      cityCopy: false,
      regHidden: false,
      factHidden: false,
      date_of_birth: '',
      data_birth_pr: '',
      dataForm: {
        type_service: 'nn_desk', // Тип сервиса
        fld_368: '', // снилс
        date_of_birth: '', // дата рождения
        fld_424: '', // email
        fld_386: 52910029, // тип карты
        fld_404: '---', // Учереждение 
        fld_405: '---', // Класс
        fld_412: '---', // Вуз
        fld_407: '---', // Курс
        fld_461: '---', // Номер лицевого счета
        fld_391: '---', // Номер льготы
        last_name: '', //Фамилия
        first_name: '', //Имя
        second_name: '', //Отчество
        id_ref_sex: 'male', // Пол
        fld_422: '', // Адрес регистрации 
        fld_423: '', // Адрес проживания 
        fld_388: '', // Домашний телефон
        fld_369: '', // Телефон
        id_ref_doc: 'Паспорт', // Тип документа
        series_doc: '', // Серия
        num_doc: '', // Номер паспорта
        fld_428: 'no', // Адрес регистрации совпадает с местом проживания
        fld_370: '', // ФИО представителя
        fld_387: '', // Серия и номер паспорта
        fld_462: '', // Кем выдан паспорт представителя
        fld_464: '', // Дата выдачи паспорта
        fld_482: '', // Телефон представителя
        fld_483: '', // E-mail представителя
        fld_421: '', // Точка где забирать
      },
    }
  },
  mounted() {
    axios.get(api + '/include/regions.php').then((response) => {
      this.listCity = response.data;
    })
    this.dataForm.fld_424 = this.$store.getters.profile_date("email-address");
  },
  methods: {
    focus(e) {
      this.focusInput(e.target);
      e.target.classList.remove('error');
    },
    blur(e) {
      this.blurInput(e.target);
    },
    stepPrev() {
      window.scrollTo(0, 0, {
        behavior: 'smooth'
      });
      this.stateTab -= 1;
    },
    stepNext() {
      // var contains = true;
      // var form = document.querySelector(`[data-tab="${this.stateTab}"]`);
      // if (this.stateTab == 1) {
      //   var dateInput = form.querySelector('[name="date_of_birth"]') || '';
      //   form.querySelectorAll('.required').forEach((item) => { // проверяем все обязательные поля
      //     if (item.value != undefined && !item.value.trim().length) {
      //       this.createError(item, `Введите ${item.previousElementSibling.textContent}`);
      //       this.statusError = true;
      //     }
      //   });
      //   contains = [...form.querySelectorAll('.required')].every(function (entry) {
      //     return !entry.classList.contains('error');
      //   });
      //   if (dateInput.value == '') { // проверяем дату рождения на заполнение
      //     if (!dateInput.nextElementSibling) {
      //       var formTooltip = document.createElement("span");
      //       formTooltip.className = "error-tooltip";
      //       formTooltip.textContent = 'Введите дату рождения';
      //       formTooltip.style.opacity = "1";
      //       formTooltip.style.transform = "scale(1)";
      //       dateInput.classList.add('error');
      //       dateInput.parentElement.parentElement.parentElement.classList.add('error');
      //       dateInput.after(formTooltip);
      //       this.statusError = true;
      //     }
      //   }
      //   if (!this.statusCheckPolicy) { // проверяем согласие на персональные данные
      //     form.querySelector('[type="checkbox"]').classList.add('error');
      //     this.statusError = true;
      //   } else {
      //     form.querySelector('[type="checkbox"]').classList.remove('error');
      //     this.statusError = false;
      //   }
      // }
      // if (this.stateTab == 2) {
      //   form.querySelectorAll('.required').forEach((item) => { // проверяем все обязательные поля
      //     if (item.value != undefined && !item.value.trim().length) {
      //       this.createError(item, `Введите "${item.previousElementSibling.textContent}"`);
      //       this.statusError = true;
      //     }
      //   });
      //   contains = [...form.querySelectorAll('.required')].every(function (entry) {
      //     return !entry.classList.contains('error');
      //   });
      // }
      // if (this.stateTab == 3) {
      //   form.querySelectorAll('.required').forEach((item) => { // проверяем все обязательные поля
      //     if (item.value != undefined && !item.value.trim().length) {
      //       this.createError(item, `Введите "${item.previousElementSibling.textContent}"`);
      //       this.statusError = true;
      //     }
      //   });
      //   contains = [...form.querySelectorAll('.required')].every(function (entry) {
      //     return !entry.classList.contains('error');
      //   });
      //   if (contains) {
      //     this.statusError = false;
      //   }
      // }
      // if (!this.statusError && this.statusCheckPolicy && contains && !this.isSchool) {
      //   window.scrollTo(0, 0, {
      //     behavior: 'smooth'
      //   });
      //   if (this.allowedСards) {
      //     this.stateTab = 'snilsFall';
      //     return;
      //   }
      // }
        this.stateTab += 1;
      if (this.stateTab == 0) {
        window.scrollTo(0, 0, {
          behavior: 'smooth'
        });
        this.stateTab += 1;
      }

    },
    // блок копирования адреся проживания
    copyCityData() {
      const inputs = document.querySelector('[data-address="fact"]').querySelectorAll('.form-field');
      if (this.dataForm.fld_428 == 'no') {
        this.dataForm.fld_428 = 'yes';
        this.dependenceAddress();
        inputs.forEach((item) => {
          if (item.value.trim().length) {
            item.previousElementSibling.style.cssText = 'font-size: 12px; top: -19px;';
            item.setAttribute('readonly', 'readonly');
            item.parentElement.style.zIndex = '-1';
            this.cityCopy = true;
            if (item.nextElementSibling && item.nextElementSibling.classList.contains('error-tooltip')) {
              item.nextElementSibling.remove();
              item.classList.remove('error');
            }
          }
        })
        if (document.querySelector('[name="r_np"]').name == 'r_np' && document.querySelector('[name="r_np"]').value != '') {
          document.querySelector('[name="f_ct"]').classList.remove('required');
        }
      } else {
        this.dataForm.fld_428 = 'no';
        this.dataForm.fld_423 = '';
        inputs.forEach((item) => {
          item.removeAttribute('readonly');
          item.parentElement.removeAttribute('style');
          item.parentElement.classList.remove('check');
          item.previousElementSibling.removeAttribute('style');
          item.value = '';
        })
        document.querySelector('[name="f_ct"]').classList.add('required');
      }
    },
    // Проверка даты рождения на возраст
    checkDate(e) {
      this.year = Math.floor((new Date().getTime() - new Date(e)) / (24 * 3600 * 365 * 1000));
      const dateInput = document.querySelector('[name="date_of_birth"]');
      this.focusInput(dateInput.parentElement.parentElement)
      if (this.year < 14) {
        this.isSchool = true;
        if (this.isSchool) { // проверяем дату рождения на заполнение
          if (!dateInput.nextElementSibling) {
            dateInput.classList.add('error');
            this.statusError = true;
          }
        }
      } else {
        this.isSchool = false;
        if (dateInput.classList.contains('error')) {
          dateInput.classList.remove('error');
          if (dateInput.nextElementSibling.length) {
            dateInput.nextElementSibling.remove();
          }
        }

      }

    },
    checkLengthRegAddr(e) {
      this.blur(e)
      const inputs = document.querySelector('[data-address="reg"]').querySelectorAll('.required');
      const inputsVal = document.querySelector('[data-address="reg"]').querySelectorAll('.form-field');
      const valueState = [...inputs].every(function (entry) {
        return entry.value != '';
      });
      if (valueState) {
        this.dataForm.fld_422 = '';
        this.cityCopy = true;
        inputsVal.forEach((item) => {
          this.dataForm.fld_422 += item.value + '~#'
        })
        this.dependenceAddress();
      }
    },
    checkLengthFactAddr(e) {
      this.blur(e)
      const inputs = document.querySelector('[data-address="fact"]').querySelectorAll('.required');
      const inputsVal = document.querySelector('[data-address="fact"]').querySelectorAll('.form-field');
      const valueState = [...inputs].every(function (entry) {
        return entry.value != '';
      });
      if (valueState) {
        this.dataForm.fld_423 = '';
        this.cityCopy = true;
        inputsVal.forEach((item) => {
          this.dataForm.fld_423 += item.value + '~#'
        })
      }
    },
    dependenceAddress() {
      if (this.dataForm.fld_428 == 'yes') {
        this.dataForm.fld_423 = '';
        const inputsReg = document.querySelector('[data-address="reg"]').querySelectorAll('.form-field');
        const inputsFact = document.querySelector('[data-address="fact"]').querySelectorAll('.form-field');
        inputsFact.forEach((item, index) => {
          item.value = inputsReg[index].value;
          this.dataForm.fld_423 += item.value + '~#'
        });
      }
    },
    //список мест выдачи
    pointCard() {
      this.pointPickup = null;
      if (this.issuance != null) {
        axios.get(api + `/include/dip.php?s=${this.issuance.id}&trans`).then((response) => {
          this.pointPickupList = response.data
        })
      }
    },
    // Проверка снилс
    checkSnils: function (e) {
      this.blur(e)
      var snils = e.target.value.split('-').join('').split(''); // получаем снилс трасформируем его в строку
      var snilsEnd = e.target.value.split('-').join('').split(' ').join('');
      var state = false;
      var checkSum = snils[10] + snils[11]; // суммируем остаток 
      var sum = snils[0] * 9 + snils[1] * 8 + snils[2] * 7 + snils[3] * 6 + snils[4] * 5 + snils[5] * 4 + snils[6] * 3 + snils[7] * 2 + snils[8] * 1;
      if (sum < 100 && sum == checkSum) { // Проверяем на сумму
        state = true;
      } else if ((sum == 100 || sum == 101) && checkSum == 0) {
        state = true;
      } else if (sum > 101 && (sum % 101 == checkSum || sum % 101 == 100 && checkSum == 0)) {
        state = true;
      } else {
        state = false;
      }
      if (e.target.value.length > 0 && !state) { // Проверяем длину введенной строки и сумму чисел снилса
        this.createError(e.target, 'Введите корректный СНИЛС');
        this.statusError = true;
      } else {
        axios.get(api + `/include/snils/check_rall.php?s=${snilsEnd}`).then((response) =>{
          if (response != 'N') {
            this.allowedСards = +response[0];
            return;
          }
          this.allowedСards = 0
        })
      }
    },

    //добавление файла
    addFile: function (e) {
      var fileContainer = e.target.parentElement.parentElement;
      var file = e.target.files[0];
      var fileInfo = fileContainer.querySelector('.file-box__file');
      var fileLabel = fileContainer.querySelector('.file-box__add');
      if (fileContainer.querySelectorAll('.error-tooltip').length) {
        fileContainer.querySelector('.error-tooltip').remove()
      }
      if (file.size > 1048576 || file.type != 'image/jpeg') {
        this.createError(e.target.parentElement, 'Файл не подходит под требования');
        this.statusError = true;
      } else {
        if (fileContainer.querySelectorAll('.error-tooltip').length) {
          fileContainer.querySelector('.error-tooltip').remove()
        }
        fileInfo.querySelector("span").textContent = file.name;
        fileInfo.style.display = "flex";
        fileLabel.style.display = "none";
        this.statusError = false;
      }
    },
    removeFile: function (e) {
      var fileContainer = e.target.parentElement.parentElement;
      var fileInfo = fileContainer.querySelector('.file-box__file');
      var fileLabel = fileContainer.querySelector('.file-box__add');
      fileInfo.style.display = "none";
      fileLabel.style.display = "block";
      fileLabel.querySelector('input').value = ''
    },
    // Длина ИНН
    checkLengthINN: function (e) {
      if (e.target.value.length > 0 && e.target.value.length < 12) {
        this.createError(e.target, 'Указывайте ваш ИНН полностью - 12 символов');
        this.statusError = true;
      } else {
        this.statusError = false;
      }
    },
    // Длина номера телефона
    checkLengthPhone: function (e) {
      this.blur(e)
      if (e.target.value.length > 0 && e.target.value.length < 16) {
        this.createError(e.target, 'Номер телефона указан не полностью');
        this.statusError = true;
      } else {
        this.statusError = false;
      }
    },
    // Длина ОМС
    checkLengthOMS: function (e) {
      if (e.target.value.length > 0 && e.target.value.length < 16) {
        this.createError(e.target, 'Введите номер полиса ОМС - 16 символов');
        this.statusError = true;
      } else {
        this.statusError = false;
      }
    },
    // Проверка email
    checkInputEmail(e) {
      var pattern = /^[a-z0-9-_.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{1,6}$/i;
      if (!pattern.test(e.target.value) && e.target.value != "") {
        this.createError(e.target, 'Некорректный email');
        this.statusError = true;
      } else {
        this.statusError = false;
      }
    },
    // Проверка на язык ввода
    typeText(e) {
      var re = /[^а-яА-ЯёЁ\s]/g;
      if (re.test(e.target.value)) {
        e.target.value = e.target.value.replace(re, '')
      }
    },
    typeTextaNumber(e) {
      var re = /[^а-яА-ЯёЁ0-9\s]/g;
      if (re.test(e.target.value)) {
        e.target.value = e.target.value.replace(re, '')
      }
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    createError(elem, mess) {
      mess = mess || "Поле обязательное для заполнения";
      var formTooltip = document.createElement("span");
      formTooltip.className = "error-tooltip";
      formTooltip.textContent = mess;
      formTooltip.style.opacity = "1";
      formTooltip.style.transform = "scale(1)";
      if (!elem.parentElement.querySelector('.error-tooltip')) {
        elem.after(formTooltip);
        elem.classList.add('error');
        elem.parentElement.classList.add('error');
      }
    },
    sendForm(e) {
      if (this.stateTab == 4) {
        var arr_result = new FormData();
        // for (key in this.dataForm) {
        //   arr_result.append(key, this.dataForm[key])
        // };
        [...e.target].forEach((item) => {
          if (item.type == 'file' && item.value == '') {
            this.createError(item.parentElement, 'Файл не добавлен');
            this.statusError = true;
          } else if (item.type == 'file' && item.value != '') {
            arr_result.append(item.name, item.files[0]);
          }
        });
        if (!this.statusError && this.sendReqStatus) {
          this.sendReqStatus = false;
          var url = '/include/form/applyforcard.php';
          fetch(url, {
            body: arr_result,
            method: "post"
          }).then((response) => {
            if (response.ok) {
              this.stateTab = 'success';
              window.scrollTo(0, 0, {
                behavior: 'smooth'
              });
            }
          }).catch((error) => {
            console.log("Ошибка передачи:", error);
          });
        }
      }
    }
  },
  watch: {
    pointPickup: function (newV) {
      if (newV != null) {
        this.dataForm.fld_421 = newV.id;
      }
    },
    date_of_birth: function (newV) {
      this.dataForm.date_of_birth = new Date(newV).toLocaleDateString();
    },
    data_birth_pr: function (newV) {
      this.dataForm.fld_464 = new Date(newV).toLocaleDateString();
    }
  },
  components: {
    baseLayout,
    Multiselect,
    Datepicker
  },
}
</script>
