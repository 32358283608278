<template>
  <div class="content content_indent">
    <div class="content__top">
      <div @click="$emit('close')" class="content__backed">
        <svg width="25" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="9" width="25" height="2" rx="1" fill="#90CAF9" />
          <path d="m10 19-9-9 9-9" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <p class="content__title">Сервис: Сменить тариф</p>
      <p class="content__sign">Выберите удобный тариф из доступных в вашем регионе</p>
    </div>
    <div class="content__box">
      <div class="tarif-change">
        <div class="tarif-change__list">
          <div class="tarif-change__item" v-for="item in card_operations" :key="item.series">
            <div class="tarif-change__title">{{ item.description }}</div>
            <div class="tarif-change__price">{{ event_price(item.min) }} ₽</div>
            <button class="tarif-change__more" @click="$emit('open', item.series)">Выбрать</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Tarif-change',
  computed: {
    ...mapGetters(['card_operations']),
  },
  methods: {
    event_price(number){
      if (number == 0) return number;
      const numb = String(number)
      return numb.slice(0, -2) + '.' + numb.slice(-2)
    }
  }
}

</script>