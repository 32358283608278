<template>
  <baseLayout>
    <card_warning :state="stateWarning" :number="number" />
    <div class="info-card">
      <div class="info-card__top" :class="'color-' + user_card.color">
        <div class="info-card__left">
          <div class="info-card__name">{{ user_card.name != undefined ? user_card.name : 'Транспортная карта' }}</div>
          <div class="info-card__number">{{ user_card.number.replace(/(?=(?:.{3})*$)/g, ' ') }}
            <div class="info-card__number-edit" @click="open_edit_popup" v-if="!user_card.type">
              <svg width="19" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.97 1.05a3.607 3.607 0 0 0-5.075 0L1.828 11.118c-.082.081-.163.163-.163.245L.03 17.093a.742.742 0 0 0 .49.9H.93l5.648-1.637c.082 0 .163-.081.245-.163l2.537-2.538 7.53-7.53a3.535 3.535 0 0 0 .082-5.075ZM1.747 16.275l.9-3.357 2.374 2.374-3.274.983Zm8.1-5.076-1.145 1.146-.41.41-1.964 1.964-3.026-3.192L11.65 3.26l3.111 3.109-4.915 4.83Zm6.139-6.057-.245.246-3.107-3.11.246-.245a2.276 2.276 0 0 1 3.11 0 2.277 2.277 0 0 1 0 3.11h-.004Z"
                  fill="#fff" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="info-card__bottom" v-if="!openServiceRefill && !openTarifChange">
        <div class="info-card__over-tarif">
          <div class="info-card__sectors" v-if="sector.length > 1">
            <button class="info-card__sector" v-for="item in sector" :key="item.sector"
              :class="{ 'active': item.sector == activeSelector }" @click="change_sector(item.sector)"><span
                :class="'info-card__icon info-card__icon_ic-' + item.sector"></span>
            </button>
          </div>
          <div class="info-card__tarif">
            <p class="info-card__caption">{{ sector_name(activeSelector) }}</p>
            <div class="info-card__txt">{{ sectorDesc != undefined ? sectorDesc.description : '' }}</div>
            <div class="info-card__control" v-if="cardOption != null && cardOption.operations && !endCard">
              <div class="info-card__wrap">
                <button class="info-card__btn btn" @click="openServiceRefill = true">Пополнить</button>
              </div>
              <button class="info-card__btn btn" v-if="cardOption.operations.length > 1"
                @click="openTarifChange = true">Сменить тариф</button>
            </div>
            <div class="info-card__no-control" v-if="cardOption == null || cardOption.operations == undefined || endCard">
              Пополнение
              временно не доступно, попробуйте позже.</div>
          </div>
        </div>
        <div class="info-card__loader" v-if="loaderTarif">
          <img src="@/assets/img/loader.gif" alt="">
          <p>Обновляем данные...</p>
        </div>
      </div>
      <div class="info-card__bottom" v-if="openTarifChange">
        <div class="info-card__tarif">
          <p class="info-card__caption">{{ sector_name(activeSelector) }}</p>
          <div class="info-card__text">{{ sectorDesc != undefined ? sectorDesc.description : '' }}</div>
          <br>
        </div>
      </div>
    </div>
    <div class="service-warning" style="margin-top: 20px;" v-if="cardSync">
      <div class="service-warning__txt" v-if="numberSync">
        <b>Незаписанная операция</b>
        <p>Оплаченную операцию №{{ numberSync }} необходимо записать на карту.
        </p>
      </div>
      <div class="service-warning__txt" v-else>
        <b>Незаписанная операция</b>
        <p>Оплаченную операцию необходимо записать на карту с помощью NFC считывателя вашего телефона или при поездке в
          транспорте на следующий день с момента оплаты</p>
      </div>
    </div>
    <div class="history-card" v-if="!openServiceRefill && !openTarifChange && !openServiceExtension">
      <p class="history-card__title">Сервисы</p>
      <div class="history-card__tabs">
        <div class="history-card__btn" :class="{ 'active': tabShow }" @click="change_tabs(true)"> История поездок</div>
        <div class="history-card__btn" :class="{ 'active': !tabShow }" @click="change_tabs(false)">История операций
        </div>
      </div>
      <div class="history-card__container" v-if="tabShow">
        <div class="history-card__box">
          <!-- <div class="history-card__month">Ноябрь</div> -->
          <div class="history-card__contain" v-if="trips.length">
            <div class="history-card__elm" @click="open_tab_one" v-for="(item, index) in trips" :key="index">
              <div class="history-card__head">
                <div class="history-card__icon" :class="'history-card__icon_icon-' + item.transport"></div>
                <div class="history-card__type">{{ type_transport(item.transport) }}</div>
                <div class="history-card__road">Маршрут {{ item.route }}</div>
                <div class="history-card__date">{{ currentDate(item.time, 'L LT') }}</div>
                <div class="history-card__price" v-if="item.unit == 1">По тарифу</div>
                <div class="history-card__price" v-else>{{ item.amount / 100 }} ₽</div>
              </div>
              <div class="history-card__bottom">
                <ul class="history-card__list">
                  <li class="history-card__item">
                    <p>Тариф на карте</p>
                    <p> {{ item.tariff }}</p>
                  </li>
                  <li class="history-card__item">
                    <p>Вид транспорта</p>
                    <p>{{ type_transport(item.transport) }}</p>
                  </li>
                  <li class="history-card__item">
                    <p>Маршрут</p>
                    <p> {{ item.route }}</p>
                  </li>
                  <li class="history-card__item">
                    <p>Терминал</p>
                    <p> {{ item.terminal }}</p>
                  </li>
                  <li class="history-card__item">
                    <p>Перевозчик</p>
                    <p>{{ item.operator }}: {{ item.division }}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="history-card__more" @click="trip_pag" v-if="trips.length > countTrip * 14">Показать еще</div>
          </div>
          <div class="history-card__empty" v-else>
            Данные отсутствуют.
            <br>
            У вас не было поездок по данной карте за {{ trips_limit }} дней.
          </div>
        </div>
      </div>
      <div class="history-card__container" v-if="!tabShow">
        <div class="history-card__box">
          <!-- <div class="history-card__month">Ноябрь</div> -->
          <div class="history-card__contain" v-if="operationList.length">
            <div class="history-card__elm" @click="open_tab_two" v-for="(item, index) in operationList" :key="index">
              <div class="history-card__acme">
                <div class="history-card__icon" :class="'history-card__icon_icon-' + item.sector"></div>
                <div class="history-card__road">Операция №{{ item.id }}</div>
                <div class="history-card__date">{{ currentDate(item.created, 'L LT') }}</div>
                <div class="history-card__price">{{ item.amount / 100 }} ₽</div>
                <div class="history-card__tarif" v-if="item.status > 199">
                  {{ item.type == 0 ? 'Пополнение' : 'Смена тарифа' }}
                </div>
                <div class="history-card__tarif error" v-else-if="item.status > 99">Отменена</div>
                <div class="history-card__tarif" v-else-if="item.status == 1">Оплачена</div>
              </div>
              <div class="history-card__foot">
                <div class="history-card__sign">{{ sector_name(item.sector) }}</div>
                <div class="history-card__txt">{{ item.description }}</div>
                <div class="history-card__status" v-if="item.status == 201">
                  <b>Выполнена</b>
                  <p>Оплаченная операция записана на транспортную карту по NFC.</p>
                </div>
                <div class="history-card__status" v-if="item.status == 202">
                  <b>Выполнена</b>
                  <p>Оплаченную операцию запишется на карту в транспорте.</p>
                </div>
                <div class="history-card__status" v-else-if="item.status == 1">
                  <b>Оплачена</b>
                  <p>Оплаченную операцию необходимо записать на транспортную карту с помощью NFC считывателя вашего
                    телефона.</p>
                </div>
                <div class="history-card__status" v-else-if="item.status == 100">
                  <b style="color: red">Отменена</b>
                  <p>Денежные средства для оплаты операции не поступили. Уточните причину отказа в банке и попробуйте
                    оформить операцию еще раз.</p>
                </div>
                <div class="history-card__status" v-else-if="item.status == 102">
                  <b style="color: red">Отменена</b>
                  <p>Операция не записана, попробуйте позже.</p>
                </div>
              </div>
            </div>
            <div class="history-card__more" @click="oper_pag" v-if="operationList.length > countOper * 14">Показать еще
            </div>
          </div>
          <div class="history-card__empty" v-else>Данные отсутствуют.
            <br>
            У вас не было операций по данной карте.
          </div>
        </div>
      </div>
    </div>
    <service_refill v-if="openServiceRefill" @close="close_service" :card="[this.numberActive, this.activeSelector]"
      :series="series" />

    <tarif_change v-if="openTarifChange" @close="close_service" @open="open_service_refill" />

    <popup name="edit-data" :view="showPopup" @close="close_popup">
      <div class="edit-data" v-if="!statusRemoveCard">
        <div class="edit-data__heading">Редактирование</div>
        <div class="edit-data__box">
          <p class="edit-data__caption">Транспортная карта</p>
          <div class="edit-data__number">{{ user_card.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</div>
        </div>
        <div class="edit-data__box">
          <div class="edit-data__group">
            <span class="edit-data__name">Укажите название</span>
            <input type="text" class="edit-data__field" v-model="name" placeholder="Введите название" maxlength="20"
              @keydown="length_line" @focuc="focus">
          </div>
        </div>
        <div class="edit-data__box">
          <p class="edit-data__caption">Выберите цвет карты</p>
          <div class="edit-data__colors">
            <label class="edit-data__color" v-for="n in 8" :key="n">
              <input type="radio" class="edit-data__hidden" name="color" v-model="color" :value="n">
              <span class="edit-data__type" :class="'color-' + n"></span>
            </label>
          </div>
        </div>
        <div class="edit-data__control">
          <div class="edit-data__save btn" @click="update_card">Сохранить</div>
          <div class="edit-data__block btn-outline" @click="statusRemoveCard = true">Удалить карту</div>
        </div>
      </div>
      <div class="edit-data" v-if="statusRemoveCard">
        <div class="edit-data__close" v-if="!beforeClose">
          <p class="edit-data__sign">
            Вы уверены, что хотите удалить <br> транспортную карту <br> <b>{{
      user_card.number.replace(/(?=(?:.{3})*$)/g,
        ' ') }}</b> из профиля?
          </p>
          <div class="edit-data__dblcntr">
            <div class="edit-data__cls btn" @click="statusRemoveCard = false">Нет</div>
            <div class="edit-data__rem btn-outline" @click.once="remove_card">Удалить</div>
          </div>
        </div>
        <div class="edit-data__close" v-if="beforeClose">
          <p class="edit-data__sign">
            Транспортная карта <br> <b>{{ user_card.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</b> <br> удалена из вашего
            профиля
          </p>
          <div class="edit-data__center">
            <div class="edit-data__cls btn" @click="close_popup">Закрыть</div>
          </div>
        </div>
      </div>
    </popup>
  </baseLayout>
</template>

<script>

import { mapState, mapGetters } from 'vuex'

import { Base64 } from 'js-base64'

import baseLayout from '@/layout/base'
import popup from '@/layout/popup'

import card_warning from '@/components/core/card_warning'
import service_refill from '@/components/services/service.refill'
import tarif_change from '@/components/services/service.change-tarif'

import axios from 'axios'

export default {
  name: 'Mycards',
  data() {
    return {
      numberActive: '',
      tabShow: true,
      activeSelector: 0,
      showPopup: false,
      loaderTarif: true,

      sector: [],

      openServiceRefill: false,
      openServiceExtension: false,
      openTarifChange: false,
      name: '',
      color: '',
      series: null,

      stateWarning: undefined,
      endCard: false,
      number: null,
      countTrip: 1,
      countOper: 1,

      cardSync: false,
      numberSync: null,

      trips: [],
      operationList: [],

      statusRemoveCard: false,
      beforeClose: false,
    }
  },
  created() {
    this.numberActive = Base64.decode(this.$route.params.id).replace('end','');
    this.endCard =  Base64.decode(this.$route.params.id).indexOf("end") >= 0 ? true : false;
    this.activeSelector = this.card_sector[0].sector;
    this.sector = [];
    axios.all(this.card_sector.map((item) => this.$store.dispatch('get_card_info', [this.numberActive, item.sector]))).then(
      (data) => {
        data.map(el => {
          if (!el.status) {
            this.sector.push({ ...JSON.parse(el.state), "sector": el.sector })
          }
        })
        this.loaderTarif = false;
      },
    );
    this.$store.dispatch('get_card_option', [this.numberActive, this.activeSelector]).then(res => {
      const data = JSON.parse(res.data.options)
      if (data.sync) {
        this.cardSync = true
        this.numberSync = data.operation.id
        return;
      }
      this.cardSync = false
    })
    this.$store.dispatch('get_card_history', this.numberActive).then((res) => {
      if (res) return;
      this.trips = this.$store.getters.trip_list(this.countTrip)
    });
    this.$store.dispatch('get_operation_list', this.numberActive).then((res) => {
      if (res) return;
      this.operationList = this.$store.getters.operation_list(this.countOper);
    });
  },
  computed: {
    ...mapState(['cardOption']),
    ...mapGetters(['card_sector', 'trips_limit']),

    user_card() {
      const cards = this.$store.getters.cards;
      const card = cards.find(item => item.number === this.numberActive);
      const virtCard = {
        type: 'virt',
        color: 1,
        name: 'Виртуальная карта',
        number: this.numberActive
      };
      return card == undefined ? virtCard : card;
    },
    sectorDesc() {
      if (!this.sector.length) return
      return this.sector.find((item) => item.sector == this.activeSelector)
    }
  },
  methods: {
    change_tabs(state) {
      document.querySelectorAll('.history-card__foot').forEach(item => item.removeAttribute('style'))
      document.querySelectorAll('.history-card__bottom').forEach(item => item.removeAttribute('style'))
      this.tabShow = state;
    },
    type_transport(item) {
      let type = ['Метро', 'Автобус', 'Троллейбус', 'Трамвай', 'Региональный', 'Пригородный автобус', 'Канатная дорога', 'Поезд'];
      return type[item - 1];
    },
    sector_name(id) {
      const sector = this.$store.getters.card_sector
      return sector.find(item => item.sector == id).name
    },
    trip_pag() {
      this.countTrip++;
      this.trips = this.$store.getters.trip_list(this.countTrip);
    },
    oper_pag() {
      this.countOper++;
      this.operationList = this.$store.getters.operation_list(this.countOper);
    },
    change_sector(sector) {
      this.loaderTarif = true;
      this.activeSelector = sector;
      setTimeout(() => {
        this.loaderTarif = false;
      }, 1200);
      this.$store.dispatch('get_card_option', [this.numberActive, this.activeSelector]).then(res => {
        if (!res.data.status) {
          const data = JSON.parse(res.data.options)
          if (this.cardOption.sync && this.cardOption.operation != undefined && this.cardOption.operation.id > 0) {
            this.$store.dispatch('get_operation_id', this.cardOption.operation.id);
          }
          if (data.sync) {
            this.cardSync = true
            this.numberSync = data.operation.id
            return;
          }
          this.cardSync = false
        }
      })
    },
    open_tab_one(e) {
      const parentHead = e.target.closest('.history-card__head');
      if (parentHead != null) {
        if (parentHead.classList.contains('open')) {
          parentHead.classList.remove('open')
          parentHead.nextElementSibling.style.display = 'none';
        } else {
          parentHead.classList.add('open')
          parentHead.nextElementSibling.style.display = 'block';
        }
      }
    },
    open_tab_two(e) {
      const parentHead = e.target.closest('.history-card__acme ');
      if (parentHead != null) {
        if (parentHead.classList.contains('open')) {
          parentHead.classList.remove('open')
          parentHead.nextElementSibling.style.display = 'none';
        } else {
          parentHead.classList.add('open')
          parentHead.nextElementSibling.style.display = 'block';
        }
      }
    },
    open_edit_popup() {
      const card = this.$store.getters.cards.find(item => item.number === this.numberActive);
      this.name = card.name == undefined ? '' : card.name;
      this.color = card.color;
      this.showPopup = true;
    },
    update_card() {
      this.$store.dispatch('update_card_favorite', [this.numberActive, this.name, this.color])
        .then((response) => {
          if (!response) {
            document.querySelector('body').removeAttribute('style');
            this.showPopup = false;
          }
        });
    },
    remove_card() {
      this.$store.dispatch('remove_card_favorite', this.numberActive)
        .then((response) => {
          if (!response) {
            document.querySelector('body').removeAttribute('style');
            this.beforeClose = true;
          }
        });
    },
    focus(e) {
      e.target.parentElement.classList.remove('error');
    },
    length_line(e) {
      const val = e.target;
      if (val.value.length > val.maxLength) {
        val.value = val.value.slice(0, val.maxLength);
      }
    },
    open_service_refill(number) {
      this.series = number;
      this.openServiceRefill = true;
      this.openTarifChange = false;
    },
    close_service() {
      this.openServiceRefill = false;
      this.openServiceExtension = false;
      this.openTarifChange = false;
      this.series = null;
      this.$store.dispatch('get_card_option', [this.numberActive, this.activeSelector]).then(res => {
        const data = JSON.parse(res.data.options)
        if (data.sync) {
          this.cardSync = true
          this.numberSync = data.operation.id
          return;
        }
        this.cardSync = false
      })
    },
    close_popup() {
      this.statusRemoveCard = false;
      this.showPopup = false;
      document.querySelector('body').removeAttribute('style');
      if (this.beforeClose) {
        this.beforeClose = false;
        this.$router.push('/');
      }
    }
  },
  components: {
    baseLayout,
    popup,
    service_refill,
    tarif_change,
    card_warning,
  },
  watch: {
    '$route'() {
      this.numberActive = Base64.decode(this.$route.params.id).replace('end','');
      this.endCard =  Base64.decode(this.$route.params.id).indexOf("end") >= 0 ? true : false;
      this.activeSelector = this.card_sector[0].sector;
      this.loaderTarif = true;
      this.countTrip = 1;
      this.sector = [];
      axios.all(this.card_sector.map((item) => this.$store.dispatch('get_card_info', [this.numberActive, item.sector]))).then(
        (data) => {
          data.map(el => {
            if (!el.status) {
              this.sector.push({ ...JSON.parse(el.state), "sector": el.sector })
            }
          })
          this.loaderTarif = false;
        },
      );
      this.$store.dispatch('get_card_option', [this.numberActive, this.activeSelector]).then(res => {
        const data = JSON.parse(res.data.options)
        if (data.sync) {
          this.cardSync = true
          this.numberSync = data.operation.id
          return;
        }
        this.cardSync = false
      })
      this.$store.dispatch('get_card_history', this.numberActive).then(() => {
        this.trips = this.$store.getters.trip_list(this.countTrip)
      });
      this.$store.dispatch('get_operation_list', this.numberActive).then(() => {
        this.operationList = this.$store.getters.operation_list(this.countOper)
      });
      this.countOper = 1;
      document.querySelector('body').removeAttribute('style');
      this.close_service()
    }
  },
}

</script>
