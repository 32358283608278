<template>
  <div class="sidebar" ref="sidebar">
    <div class="sidebar__head">
      <button class="sidebar__open" @click="open_sidebar">
        <svg width="28" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m22 1 5 5-5 5" stroke="#2196F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path stroke="#2196F3" stroke-width="2" stroke-linecap="round" d="M25 6H1" />
        </svg>
      </button>
      <div class="sidebar__caption">Мои карты</div>
    </div>
    <div class="sidebar__cards">
      <div class="sidebar__list" v-if="cards != 7">
        <router-link class="sidebar__card" :to="'/mycards/' + encoder(item.number)" v-for="item in cards"
          :key="item.number">
          <span :class="'color-' + item.color"></span>
          <div class="sidebar__title">{{ item.name != undefined ? item.name : 'Транспортная карта' }}</div>
          <div class="sidebar__number">{{ item.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</div>
        </router-link>
      </div>
      <div class="sidebar__empty" v-if="cards == 7">Транспортные карты отсутствуют</div>
      <div class="sidebar__virtual" v-if="emit_card != null">
        <router-link class="sidebar__card" :to="'/mycards/' + encoderVirt(item.number, isFinalLicense(item.expdate))" v-for="item in emit_card"
          :key="item.number">
          <span class="color-1"></span>
          <!-- <div class="sidebar__title">Виртуальная карта</div> -->
          <div class="sidebar__number">{{ item.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</div>
          <div class="sidebar__sign" :class="{ color_red: isFinalLicense(item.expdate) }"
            v-if="currentDate(item.expdate) != undefined">Действует до {{ currentDate(item.expdate, 'L') }}</div>
        </router-link>
      </div>
    </div>
    <button class="sidebar__add" @click="open_add_card_popup">
      <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.998 28a14 14 0 1 1 9.9-4.101 13.909 13.909 0 0 1-9.9 4.101Zm0-26.354A12.353 12.353 0 1 0 26.351 14 12.366 12.366 0 0 0 13.998 1.646Z"
          fill="#2196F3" />
        <path
          d="M13.175 18.941v-4.117H9.058a.824.824 0 0 1 0-1.647h4.117V9.059a.824.824 0 0 1 1.647 0v4.118h4.118a.824.824 0 0 1 0 1.647h-4.118v4.117a.824.824 0 0 1-1.647 0Z"
          fill="#2196F3" />
      </svg>
      <span>Добавить карту</span>
    </button>
    <popup name="edit-data" :view="showPopupEdit" @close="close_popup">
      <div class="edit-data" v-if="statusDate == null">
        <p class="edit-data__holder">Добавление транспортной карты</p>
        <div class="edit-data__box">
          <div class="edit-data__group" ref="number">
            <span class="edit-data__name" data-sign="Номер карты"> Номер карты</span>
            <input type="text" class="edit-data__field edit-data__field_big" id="add_number" @focus="focus"
              v-mask="'### ### ###'" v-model="number" placeholder="000 000 000">
          </div>
        </div>
        <div class="edit-data__box">
          <div class="edit-data__group" ref="name">
            <span class="edit-data__name">Название карты</span>
            <input type="text" class="edit-data__field" placeholder="Введите название" maxlength="20"
              @keydown="length_line" @focus="focus" v-model="name">
          </div>
        </div>
        <div class="edit-data__box">
          <p class="edit-data__caption">Выберите цвет карты</p>
          <div class="edit-data__colors">
            <label class="edit-data__color" v-for="n in 8" :key="n">
              <input type="radio" class="edit-data__hidden" name="color" v-model="color" :value="n">
              <span class="edit-data__type" :class="'color-' + n"></span>
            </label>
          </div>
        </div>
        <div class="edit-data__control">
          <div class="edit-data__save btn" @click="add_card">Добавить</div>
        </div>
      </div>
      <div class="edit-data__state-info" v-if="statusDate != null && !statusDate">
        <img src="@/assets/icon/icon-check-success.svg" alt="">
        <p>Готово</p>
        <p>Ваша карта добавлена</p>
        <button class="btn" @click="close_popup">Закрыть</button>
      </div>
      <div class="edit-data__state-info" v-if="statusDate > 0">
        <img src="@/assets/icon/icon-fail.svg" alt="">
        <p>Ошибка</p>
        <p v-if="statusDate == 1">Карта уже есть в списке</p>
        <p v-if="statusDate == 6">Обновите страницу</p>
        <p v-if="statusDate == 7">Информации о карте не найдена. Попробуйте позже.</p>
        <button class="btn" @click="close_popup">Закрыть</button>
      </div>
    </popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import popup from '@/layout/popup'

import { Base64 } from 'js-base64'

export default {
  name: 'Sidebar',
  mounted() {
    this.$store.dispatch('get_card_favorite');
    this.$store.dispatch('get_card_issued')
    window.addEventListener("resize", this.add_class_sidebar);
    window.addEventListener("load", this.add_class_sidebar);
    const getRandomNumber = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
    this.color = getRandomNumber(1, 8)
  },
  data() {
    return {
      showPopupEdit: false,

      statusDate: null,

      number: '',
      name: '',
      color: 5
    }
  },
  computed: {
    ...mapGetters(['cards', 'emit_card', 'find_card']),
  },
  methods: {
    encoder(number) {
      return Base64.encode(number)
    },
    encoderVirt(number, endCard) {
      if(endCard) return Base64.encode(number + 'end')
      return Base64.encode(number)
    },
    open_sidebar() {
      const sidebar = document.querySelector('.sidebar');
      sidebar.parentElement.classList.toggle('open');
      if (sidebar.classList.contains('hide')) {
        setTimeout(() => {
          sidebar.classList.remove('hide');
        }, 300);
      } else {
        sidebar.classList.add('hide');
      }
      document.querySelector('.sidebar__open').classList.toggle('open');
    },
    add_class_sidebar() {
      if (document.querySelectorAll('.sidebar').length) {
        if (window.innerWidth < 960) {
          document.querySelector('.sidebar').classList.add('hide');
          return;
        }
        document.querySelector('.sidebar').classList.remove('hide');
      }
    },
    add_card() {
      const number = this.number.replace(/\s/g, "");
      if (this.number == '' || this.number.length < 11) {
        this.$refs.number.classList.add('error');
        this.$refs.number.querySelector('span').textContent = 'Номер карты должен содержать 9 символов';
        this.$refs.number.querySelector('span').style.color = 'red'
        return
      }
      if (this.find_card(number)) {
        this.statusDate = 1;
        return
      }
      this.$store.dispatch('get_card_info', [number, this.$store.getters.card_sector[0].sector]).then((res) => {
        if (!res.status) {
          const link = this.encoder(number);
          this.$store.dispatch('add_card_favorite', [number, this.name, this.color])
            .then((response) => {
              if (!response) {
                this.statusDate = response;
                setTimeout(() => {
                  this.showPopupEdit = false;
                  this.$router.push('/mycards/' + link);
                  this.name = '';
                  this.number = '';
                  this.color = 5;
                }, 3000);
                return
              }
              this.statusDate = response;
            });
          return
        }
        this.statusDate = res.status;
      });

    },
    length_line(e) {
      const val = e.target;
      if (val.value.length > val.maxLength) {
        val.value = val.value.slice(0, val.maxLength);
      }
    },
    focus(e) {
      if (e.target.previousElementSibling.hasAttribute('data-sign') && e.target.parentElement.classList.contains('error')) {
        e.target.previousElementSibling.textContent = e.target.previousElementSibling.dataset.sign;
        this.$refs.number.querySelector('span').style.color = '#90A4AE'
      }
      e.target.parentElement.classList.remove('error');
    },
    open_add_card_popup() {
      this.showPopupEdit = true;
      setTimeout(() => {
        document.getElementById("add_number").focus();
      }, 10);
    },
    close_popup() {
      this.number = ''
      this.statusDate = null;
      this.showPopupEdit = false;
      document.querySelector('body').removeAttribute('style');
    }
  },
  components: {
    popup,
  },
}
</script>
