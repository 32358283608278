<template>
  <baseLayout>
    <div class="page-error">
      <h1 class="page-error__title">OOps!...</h1>
      <h2 class="page-error__subtitle">Ошибка 404, что-то пошло не так</h2><img
        src="@/assets/icon/pic-error.svg" alt="" class="page-error__pic">
      <p class="page-error__text">Страница с таким адресом не существует, либо была удалена</p><a href="/"
        class="btn btn_size_xs">На главную</a>
    </div>
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'

export default {
  name: 'Index',
  methods: {
  },
  components: {
    baseLayout,
  },
}
</script>
