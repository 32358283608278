<template>
  <div class="outer">
    <heading />
    <div class="content">
      <div class="content__container inner">
        <div class="content__contain first">
          <div class="content__head">
            <a href="/" class="content__back">Главное</a>
            <h1 class="content__title">Помощь</h1>
          </div>
          <div class="support">
            <div class="support__row">
              <div class="support__info">
                <div class="support__title">В разделе представлена информация по доступным пользователям услугам и сервисам.  </div>
                <div class="support__subtitle">Мы всегда открыты и готовы помощь в решении вашего вопроса. В блоке "Часто задаваемые вопросы" мы собрали ответы на наиболее важные и популярные вопросы пользователей - воспользуйтесь им для поиска информации. Если вы не нашли ответа на ваш вопрос, — напишите нам и мы вместе найдем решение. </div>
              </div>
              <div class="support__box">
                <div class="call">
                  <p class="call__title">Мы на связи</p>
                  <div class="call__sign">Операторы службы поддержки помогут оперативно решить ваш вопрос</div>
                  <div class="call__control">
                    <button @click="viewCallback = true" class="call__btn btn">Напишите нам</button>
                    <a href="https://t.me/siticard_ru_bot" class="call__tg" target="_blank">
                      <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0Zm6.9 28.152c.367-1.129 2.09-12.377 2.303-14.594.065-.671-.147-1.117-.563-1.316-.503-.242-1.247-.121-2.11.19-1.185.427-16.326 6.856-17.2 7.228-.83.352-1.614.736-1.614 1.293 0 .391.232.611.872.84.666.237 2.343.746 3.334 1.02.954.263 2.04.034 2.648-.344.646-.401 8.092-5.384 8.627-5.82.533-.437.96.122.523.56-.436.436-5.548 5.397-6.222 6.084-.818.834-.237 1.698.312 2.044.627.395 5.135 3.42 5.814 3.905.68.485 1.368.705 1.999.705.63 0 .962-.83 1.276-1.795Z"
                          fill="#229ED9" />
                      </svg>
                    </a>
                  </div>
                  <div class="call__contacts">
                    <div class="call__caption">Операторы службы</div>
                    <a href="tel:+7 (831) 250-50-00 " class="call__phone">+7 (831) 250-50-00 </a>
                    <div class="call__time">с 9:00 до 20:00</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="support__list">
              <div class="support__box">
                <router-link class="support__link" to="/all-service">
                  <p class="support__caption">Сервисы</p>
                  <img src="@/assets/icon/icon-services-list-1.svg" alt="" class="support__icon">
                </router-link>
              </div>
              <div class="support__box">
                <router-link class="support__link" to="/service-point">
                  <p class="support__caption">Пункты обслуживания</p>
                  <img src="@/assets/icon/icon-services-list-3.svg" alt="" class="support__icon">
                </router-link>
              </div>
              <div class="support__box">
                <router-link class="support__link" to="/faq">
                  <p class="support__caption">Информация</p>
                  <img src="@/assets/icon/icon-services-list-2.svg" alt="" class="support__icon">
                </router-link>
              </div>
              <!-- <div class="support__box">
                <router-link class="support__link" to="#">
                  <p class="support__caption">Пункты обслуживания в две строки</p>
                  <img src="@/assets/icon/icon-services-list-3.svg" alt="" class="support__icon">
                </router-link>
              </div>
              <div class="support__box">
                <router-link class="support__link" to="#">
                  <p class="support__caption">Информация</p>
                  <img src="@/assets/icon/icon-services-list-2.svg" alt="" class="support__icon">
                </router-link>
              </div> -->
            </div>
          </div>
        </div>
        <div class="content__contain">
          <h2 class="content__title">Часто задаваемые вопросы</h2>
          <div class="content__holder"> Здесь вы сможете
            найти ответы на часто задаваемые вопросы.</div>
            <div class="content__faq">
              <div class="faq" v-for="item in faq_list.slice(0, 5)" :key="item.id">
                <div class="faq__head" @click="openHidden">
                  {{ item.title }}
                </div>
                <div class="faq__content" v-html="item.answer">
                </div>
              </div>
              <router-link class="content__btn btn" to="/faq">Перейти к разделу</router-link>
            </div>
        </div>
      </div>
    </div>
    <modal :view="viewCallback" @close="viewCallback = false"/>
    <foot/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import heading from '@/components/core/header.vue'
import foot from '@/components/core/footer.vue'
import modal from '@/components/core/modal_call.vue'


export default {
  name: 'Support',
  data() {
    return {
      viewCallback: false,
    }
  },
  computed: {
    ...mapGetters(['faq_list'])
  },
  methods: {
    openHidden(e) {
      if (!e.target.classList.contains('active')) {
        document.querySelectorAll('.faq').forEach(item => {
          item.querySelector('.faq__head').classList.remove('active');
        })
        e.target.classList.add('active');
        return
      }
      document.querySelectorAll('.faq').forEach(item => {
        item.querySelector('.faq__head').classList.remove('active');
      })
    },
  },
  components: {
    heading,
    foot,
    modal,
  },
}
</script>
