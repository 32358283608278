import Vue from 'vue'
import VueRouter from "vue-router";
import store from './store';
import main from "@/pages/Index";
import mycards from "@/pages/Mycard";
// import appeal from "@/pages/Appeal";
// import newappeal from "@/pages/New_appeal";
import chat from "@/pages/Chat";
import news from "@/pages/News";
import newsItem from "@/pages/News_item";
import services from "@/pages/Services";
import servicesPoint from "@/pages/Service_point";
import faq from "@/pages/Faq";
import support from "@/pages/Support";
import error404 from "@/pages/404";
import ptkService from "@/pages/services/Apply_card";
import extendService from "@/pages/services/Extend_card";
import checkService from "@/pages/services/Check_card";
import frameService from "@/pages/services/frame_services";


import successPay from "@/pages/status/success-pay";

// import login from "@/pages/Login";
// import reset from "@/pages/Reset";
import register from "@/pages/Register";

import profile from "@/pages/Profile";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: main,
    meta: {
      title: "Личный кабинет пассажира | Ситикард ",
      requiresAuth: true,
    },
  },
  {
    path: "/mycards/:id",
    name: "mycards",
    component: mycards,
    meta: {
      requiresAuth: true,
      title: "Мои карты | Личный кабинет пассажира",
    },
  },
  // {
  //   path: "/appeal",
  //   name: "appeal",
  //   component: appeal,
  //   meta: {
  //     requiresAuth: true,
  //     title: "Заявки и обращения",
  //   },
  // },
  // {
  //   path: "/newappeal",
  //   name: "newappeal",
  //   component: newappeal,
  //   meta: {
  //     requiresAuth: true,
  //     title: "Новое обращение",
  //   },
  // },
  {
    path: "/news/",
    name: "news",
    component: news,
    meta: {
      requiresAuth: true,
      title: "Новости | Личный кабинет пассажира",
    },
  },
  {
    path: "/news/:id",
    name: "newsItem",
    component: newsItem,
    meta: {
      requiresAuth: true,
      title:
        "Проездные на трамвай теперь будут действовать и на четырех автобусных маршрутах | Личный кабинет пассажира",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: profile,
    meta: {
      requiresAuth: true,
      title: "Профиль | Личный кабинет пассажира",
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: chat,
    meta: {
      requiresAuth: true,
      title: "Диалог | Личный кабинет пассажира",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: faq,
    meta: {
      requiresAuth: true,
      title: "Вопросы и ответы | Личный кабинет пассажира",
    },
  },
  {
    path: "/all-service",
    name: "services",
    component: services,
    meta: {
      requiresAuth: true,
      title: "Сервисы | Личный кабинет пассажира",
    },
  },
  {
    path: "/all-service/apply-for-card",
    name: "ptkService",
    component: ptkService,
    meta: {
      requiresAuth: true,
      title: "Оформление транспортной карты | Личный кабинет пассажира",
    },
  },
  {
    path: "/all-service/extend",
    name: "extendService",
    component: extendService,
    meta: {
      requiresAuth: true,
      title:
        "Продление карты студента/школьника | Личный кабинет пассажира",
    },
  },
  {
    path: "/all-service/check",
    name: "checkService",
    component: checkService,
    meta: {
      requiresAuth: true,
      title:
        "Проверка готовности персональной карты | Личный кабинет пассажира",
    },
  },
  {
    path: "/all-service/:id",
    name: "frameService",
    component: frameService,
    meta: {
      requiresAuth: true,
      title:
        "Сервис | Личный кабинет пассажира",
    },
  },
  {
    path: "/service-point",
    name: "services-point",
    component: servicesPoint,
    meta: {
      requiresAuth: true,
      title: "Пункты обслуживания | Личный кабинет пассажира",
    },
  },
  {
    path: "/support",
    name: "support",
    component: support,
    meta: {
      requiresAuth: true,
      title: "Помощь | Личный кабинет пассажира",
    },
  },
  {
    path: "/404",
    name: "error404",
    component: error404,
    meta: {
      requiresAuth: true,
      title: "Такой страницы не найдено | Личный кабинет пассажира",
    },
  },
  // {
  //   path: "/login",
  //   component: login,
  //   meta: {
  //     title: 'Вход в личный кабинет',
  //   }
  // },
  {
    path: "/register",
    component: register,
    meta: {
      title: "Ситикард | Вход в Личный кабинет",
    },
  },
  // {
  //   path: "/reset",
  //   component: reset,
  //   meta: {
  //     title: 'Восстановление пароля',
  //   }
  // },
  {
    path: "/status/success-pay",
    name: "success-pay",
    component: successPay,
    meta: {
      requiresAuth: true,
      title: "Успешная оплата",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.app_id) {
      next()
      return
    }
    next('/register')
  } else {
    next()
  }
})

export default router