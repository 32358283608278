<template>
  <div class="news-index">
    <div class="news-index__head">
      <p class="news-index__caption">Новости</p>
      <a href="/news" class="news-index__more">Все</a>
    </div>
    <VueSlickCarousel v-bind="settings">
      <router-link class="news-index__item" v-for="item in newsListPined" :key="item.id" :to="'/news/' + item.id">
        <span class="news-index__title">{{ item.title }}</span>
        <span class="news-index__sign">{{ item.category }}</span>
      </router-link>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { mapState } from 'vuex'


export default {
  name: 'NewsIndex',
  data() {
    return {
      slide: null,
      settings: {
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        responsive: [
          {
            "breakpoint": 400,
            "settings": {
              "slidesToShow": 2,
              "arrows": false,
            }
          },
        ]
      }
    }
  },
  mounted() {
    this.$store.dispatch('get_news_list_pinned')
  },
  computed: {
    ...mapState(['newsListPined'])
  },
  components: {
    VueSlickCarousel
  },
}
</script>
