<template>
  <div class="outer">
    <heading />
    <div class="content">
      <div class="side-open-mobile">
        <button class="side-open-mobile__open" @click="open_side">
          <svg width="28" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m22 1 5 5-5 5" stroke="#2196F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path stroke="#2196F3" stroke-width="2" stroke-linecap="round" d="M25 6H1" />
          </svg>
        </button>
        <div class="side-open-mobile__caption">Мои карты</div>
      </div>
      <div class="content__inner inner">
        <div class="content__sidebar">
          <sidebar />
        </div>
        <div class="content__info">
          <slot></slot>
        </div>
      </div>
    </div>
    <ad />
    <foot />
  </div>
</template>

<script>
import heading from '@/components/core/header.vue'
import foot from '@/components/core/footer.vue'
import sidebar from '@/components/core/sidebar.vue'

import ad from '@/components/core/ad'

export default {
  name: 'Index',
  methods: {
    open_side() {
      document.querySelector('.side-open-mobile').classList.toggle('open');
      const sidebar = document.querySelector('.sidebar');
      sidebar.parentElement.classList.toggle('open');
      if (sidebar.classList.contains('hide')) {
        setTimeout(() => {
          sidebar.classList.remove('hide');
        }, 300);
        return;
      }
      sidebar.classList.add('hide');
    },
  },
  components: {
    heading,
    sidebar,
    ad,
    foot
  },
}
</script>
