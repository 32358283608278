import Vue from 'vue'
import axios from "axios";
import moment from 'moment'

import App from './App.vue'
import router from './router'

import store from './store';
import './assets/sass/global.sass'

import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask, moment);

Vue.config.productionTip = false


Vue.prototype.focusInput = (input) => {
  input.parentElement.classList.add('focus')
};
Vue.prototype.blurInput = (input) => {
  if (input.value === '') input.parentElement.classList.remove('focus')
};

Vue.prototype.currentDate = (date, type) =>{
  if(date == 0) return undefined
  moment.locale('ru');
  const localDate = moment(date, "YYYYMMDD HH:mm:ss");
  return localDate.utc(true).format(type);
},

Vue.prototype.isFinalLicense = (date) => {
  const localDate = moment(date, 'YYYYMMDD');
  return moment(localDate).isBefore(moment(), 'day')
},


Vue.prototype.det_card_type = (number) => {
  number = number.substring(0, 5)
  const re = {
    maestro: /^(5[06789]|6)[0-9]{0,}$/,
    interpayment: /^(636)\d+$/,
    mir: /^(?:220[0-4])\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{4}?$/,
    master: /^5[1-5][0-9]{14}$/,
  }
  for (var key in re) {
    if (re[key].test(number)) {
      return key
    }
  }
};

axios.interceptors.response.use(function (response) {
  if(response.data !=''){
    store.commit("update_app_error", response.data.status);
    if(response.data.status == 6){
      store.dispatch('login_app').then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
    }
  } else {
    store.commit("update_app_error", 403);
    setTimeout(() => {
      delete window.localStorage["vuex"];
      localStorage.clear();
      window.location.reload();
    }, 5000);
  }
  return response;
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
