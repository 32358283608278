<template>
  <baseLayout>
    <div class="content__contain first">
      <div class="content__head">
        <a href="/" class="content__back" v-if="lastPath == 'home'">Главная</a>
        <a href="/news" class="content__back" v-else>Новости</a>
        <h1 class="content__title">{{ title }}</h1>
      </div>
      <div class="news">
        <!-- <img src="@/assets/img/news-big.jpg" alt="" class="news__pic"> -->
        <div class="news__content" v-html="news">
        </div>
        <div class="news__info">
          <span class="news__date">{{ currentDate(created, 'L') }}</span>
          <div class="news__tags" v-if="category != null">
            <p class="news__tag">{{ category }}</p>
          </div>
        </div>
        <a href="/news" class="news__back btn">К списку новостей</a>
      </div>
    </div>
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'
export default {
  name: 'News_item',
  data() {
    return {
      news: '',
      title: '',
      category: null,
      created: '',
      lastPath: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vw) => {
      vw.lastPath = from.name;
    });
  },
  mounted() {
    const news = this.$store.getters.news_single(this.$route.params.id);
    if (news != undefined) {
      document.title = news.title + ' | Личный кабинет пассажира';
      this.title = news.title ;
      this.category = news.category ;
      this.created = news.created ;
      this.$store.dispatch('get_news_text', this.$route.params.id).then(res => {
        console.log(res)
        this.news = res.news.description.replaceAll(/href/g, 'target="_blank" $&')
      })
      return;
    }
    this.$router.push('/404');
  },
  methods: {
  },
  components: {
    baseLayout,
  },
  watch: {
  },
}
</script>
