<template>
  <div id="app">
    <systemError />
    <router-view />
    <popup name="exit-popup" :view="showPopupExit" :isClose="false" @close="close_popup">
      <div class="exit-popup">
        <p class="exit-popup__txt">
          Вы уверены, что хотите выйти из профиля?
        </p>
        <div class="exit-popup__control">
          <div class="exit-popup__btn btn" @click="showPopupExit = false">Нет</div>
          <div class="exit-popup__block btn-outline" @click="logout">Да</div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import systemError from '@/components/core/system_error'

import popup from '@/layout/popup'

export default {
  name: 'App',
  data() {
    return {
      showPopupExit: false,
    }
  },
  beforeMount(){
    if(!this.$store.getters.app_id) return;
    this.$store.dispatch('login_app');
  },
  methods: {
    logout() {
      this.showPopupExit = false;
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
      this.$store.commit('update_appid', null);
      this.$router.push('/register');
      delete localStorage['vuex'];
      window.location.reload();
    },
    close_popup(){
      this.showPopupExit = false
      document.querySelector('body').removeAttribute('style');
    }
  },
  components: {
    systemError,
    popup,
  },
  watch: {
    '$route'() {
      window.scrollTo(0, 0);
    }
  },
}
</script>
