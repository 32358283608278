<template>
  <footer class="footer">
    <div class="footer__inner inner">
      <div class="footer__top">
        <a href="/" class="footer__logo">
          <img src="@/assets/icon/logo.svg" alt="Логотип" class="footer__pic">
        </a>
        <div class="footer__control">
          <a class="footer__call btn-outline" href="/support">Обратиться в поддержку</a>
          <button class="footer__btn btn" @click="viewCallback= true">Напишите нам</button>
        </div>
      </div>
      <div class="footer__nav">
        <div class="footer__list">
          <div class="footer__col">
            <div class="footer__item">
              <router-link to="/all-service" class="footer__link"> Сервисы</router-link>
            </div>
            <div class="footer__item">
              <router-link to="/profile" class="footer__link"> Профиль</router-link>
            </div>
            <div class="footer__item">
              <router-link to="/news" class="footer__link"> Новости</router-link>
            </div>
          </div>
          <div class="footer__col">
            <div class="footer__item">
              <router-link to="/support" class="footer__link">Помощь</router-link>
            </div>
            <div class="footer__item">
              <router-link to="/faq" class="footer__link">Вопросы и ответы</router-link>
            </div>
            <!-- <div class="footer__item">
              <router-link to="/appeal" class="footer__link">Заявки и обращения</router-link>
            </div> -->
          </div>
          <div class="footer__col">
            <div class="footer__item">
              <a href="//siticard.ru/cost-of-travel-ticket/" class="footer__link" target="_blank"> Маршруты и тарифы</a>
            </div>
            <div class="footer__item">
              <router-link to="/service-point" class="footer__link">Пункты обслуживания</router-link>
            </div>
          </div>
        </div>
        <div class="footer__app-box">
          <div class="footer__app-title">Мобильное приложение</div>
          <div class="footer__app-wrap">
            <a href="https://play.google.com/store/apps/details?id=ru.novacard.siticard&hl=ru&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank" class="footer__app">
              <picture>
                <source media="(max-width: 767px)" srcset="@/assets/icon/icon-google-app--mobile.svg">
                <img src="@/assets/icon/icon-google-app.svg" alt="" class="footer__pic">
              </picture>
            </a>
            <a href="https://apps.apple.com/ru/app/siticard/id1597477976?l" target="_blank" class="footer__app">
              <picture>
                <source media="(max-width: 767px)" srcset="@/assets/icon/icon-appstore-app--mobile.svg">
                <img src="@/assets/icon/icon-appstore-app.svg" alt="" class="footer__pic">
              </picture>
            </a>
            <a href="https://apps.rustore.ru/app/ru.novacard.siticard" target="_blank" class="footer__app">
              <picture>
                <source media="(max-width: 767px)" srcset="@/assets/icon/icon-rustore-app--mobile.svg">
                <img src="@/assets/icon/icon-rustore-app.svg" alt="" class="footer__pic">
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__copy">
      <div class="footer__inner inner">
        <p class="footer__txt">© {{ year }}. Личный кабинет Пассажира</p>
      </div>
    </div>
    <modal :view="viewCallback" @close="viewCallback = false"/>
    <popup name="sessions" :isClose="false" :view="showPopupSession" @close="close_popup">
      <div class="edit-data">
        <div class="edit-data__close">
          <p class="edit-data__sign">
            Ваша сессия в Личном кабинете заканчивается. <br><b>Нужно еще время?</b>
          </p>
          <div class="edit-data__dblcntr">
            <div class="edit-data__cls btn-outline" @click="logout">Завершить</div>
            <div class="edit-data__cls btn" @click="close_popup">Остаться</div>
          </div>
        </div>
      </div>
    </popup>
  </footer>
</template>

<script>
import ifvisible from 'ifvisible.js'

import popup from '@/layout/popup'
import modal from '@/components/core/modal_call.vue'


export default {
  name: 'Footer',
  mounted() {
    const vm = this;
    // document.addEventListener('visibilitychange', () => {
    //   if (!document.hidden) {
    //     vm.timerEvent = setTimeout(() => {
    //       vm.showPopupSession = true;
    //     }, 10000);
    //   }
    // });
    document.addEventListener('click', () => {
      if (vm.timerEvent != '') {
        vm.timerEvent = '';
      }
    });
    ifvisible.on("idle", function () {
      // vm.timerEvent = setTimeout(() => {
      //   vm.showPopupSession = true;
      // }, 10000);
    });
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  data() {
    return {
      timerEvent: '',
      showPopupSession: false,
      viewCallback: false,
    }
  },
  methods: {
    close_popup() {
      location.reload();
      this.showPopupSession = false;
      document.querySelector('body').removeAttribute('style');
    },
    logout() {
      this.$parent.$parent.$parent.logout()
    },
    open_сall(){
      this.$parent.$parent.$parent.showPopupCall = true
    }
  },
  components: {
    popup,
    modal,
  },
}

</script>