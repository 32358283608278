<template>
  <baseLayout>
    <div class="content__contain first">
      <div class="content__head">
        <router-link to="/all-service" class="content__back">Сервисы</router-link>
        <h1 class="content__title">Проверка баланса и пополнение транспортной карты</h1>
      </div>
      <iframe class="services" ref="frameService" :src="link" width="100%" height="100%" scrolling="no" frameborder="0" sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-popups"
      @load="resizeIFrame()"
      ></iframe>
    </div>
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'
export default {
  name: 'Frame',
  data() {
    return {
      link: 'https://card.siticard.ru',
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    init(){
      this.link = this.$route.query.service
    },
    resizeIFrame() {
      const vw = this;
      window.addEventListener('message', function (e) {
        if (vw.link.includes(e.origin) && !isNaN(e.data)) {
          vw.$refs.frameService.height = (parseInt(e.data) - 100) + 'px';
        }
      }, false);
    }
  },
  components:{
    baseLayout,
  },
  watch: {
    '$route'() {
      this.init();
    }
  }
}
</script>
