<template>
  <baseLayout>
    <div class="content__contain first">
      <div class="content__head">
        <router-link to="/all-service" class="content__back">Сервисы</router-link>
        <h1 class="content__title">Продление карты студента/школьника</h1>
      </div>
      <div class="callback">
        <div class="callback__box" :class="{ active: stateTab == 0 }" v-if="stateTab == 0">
          <div class="release">
            <p class="release__text">Для оформление заявки заполните все поля предлагаемой формы и прикрепите
              изображения подтверждающих документов.</p>
            <div class="release__gray-box">
              <h5>Список документов для загрузки:</h5>
              <ul>
                <li>Оборотная сторона карты студента или карты школьника с фотографией
                  пользователя</li>
                <li>Разворот студенческого билета с отметкой о переводе на следующий курс или
                  справка из школы</li>
              </ul>
            </div>
            <p class="release__text">После проверки предъявленных документов на электронную почту вам будет
              отправлено подтверждение о продлении.</p>
            <div class="release__control">
              <button class="btn" @click="stateTab = 1">Продолжить</button>
            </div>
          </div>
        </div>
        <div class="callback__box" :class="{ active: stateTab == 1 }" data-form v-if="stateTab == 1">
          <div class="callback__tabs">
            <button class="callback__tabs-btn" :class="{ active: !stud }" @click="stud = !stud" style="width: 50%">Карта
              школьника</button>
            <button class="callback__tabs-btn" :class="{ active: stud }" @click="stud = !stud" style="width: 50%">Карта
              студента</button>
          </div>
          <div class="callback__group" :class="{ error: errorDouble }">
            <span class="callback__name">Номер карты</span>
            <input type="text" class="callback__field required" @focus="focus" @blur="checkCard" v-model='number' inputmode="numeric"
              v-mask="'### ### ###'">
          </div>
          <div class="callback__group" :class="{ error: errorDouble }">
            <span class="callback__name">Дата рождения</span>
            <datepicker format="dd.MM.yyyy" :language="language" :disabled-dates="disabledDates" v-model='year'
              name="date_of_birth" ref="date_birth" @input="checkCard" autocomplete="off" class="callback__date">
            </datepicker>
          </div>
          <div class="callback__group focus">
            <span class="callback__name">Email</span>
            <input type="text" class="callback__field required"  @blur="checkInputEmail"  v-model='email' inputmode="email"
              :class="{ error: errorDouble }">
          </div>
          <multiselect v-model="schoolClass" v-if="!stud" :show-labels="false" :searchable="false" track-by="id" :options="['10', '11']"
            placeholder="Выберете класс">
          </multiselect>
          <div class="callback__stud" v-if="stud">
            <div class="callback__group">
              <span class="callback__name">Студенческий билет (номер)</span>
              <input type="text" class="callback__field required" @focus="focus" @blur="blur" name='fld_391' inputmode="numeric" maxlength="14">
            </div>
            <div class="callback__group">
              <span class="callback__name">Дата рождения</span>
              <datepicker :language="language" format="dd.MM.yyyy" name="stud"
                v-model="studDate" @input="checkDateStud" autocomplete="off" class="callback__date"></datepicker>
            </div>
            <div class="system-warning" v-if="checkDateState">
              <b>ВНИМАНИЕ! </b>
              <p>Сделайте отметку в студенческом билете о продлении срока действия. <br> Студенческий билет должен быть
                действителен до 31.08.2025г.</p>
            </div>
          </div>
          <!-- <div class="warning-box" v-if="!stud" :class="{ open: checkClassState }">
            <b>ВНИМАНИЕ! </b>
            <p>Продление срока действия карты школьника необходимо только для учащихся 10 и 11 классов.</p>
          </div> -->
          <label class="callback__checkbox">
            <input type="checkbox" @change="changeCheckbox = !changeCheckbox" />
            <span>
              Я подтверждаю, что ознакомился с документом <a href="/user_agreement/" target="_blank">«Пользовательское
                соглашение» </a> и согласен на передачу информации в электронной форме заявления (в том числе
              персональных данных) по открытым каналам связи сети Интернет.
            </span>
          </label>
          <button class="btn" @click="next"
            :disabled="email == '' || number == '' || year == '' || statusError">Продолжить</button>
          <p class="callback__text" v-if="errorDouble">Если вы уверены, что указываете
            верные данные, пожалуйста свяжитесь со службой поддержки в форме связи.</p>
        </div>
        <div class="callback__box" :class="{ active: stateTab == 2 }" v-if="stateTab == 2">
          <p class="callback__text">Файлы документов, указанных в форме, должны быть ТОЛЬКО В ФОРМАТЕ JPEG.
            <br> Размер каждого из файлов не должен превышать 1 Мб.
          </p>
          <div class="callback__file">
            <p class="callback__file-name">Оборотная сторона карты студента / <br>
              школьника с фотографией пользователя</p>
            <div class="file-box">
              <div class="file-box__file">
                <span></span>
                <div class="clear-form" @click="removeFile">Удалить</div>
              </div>
              <label class="file-box__add" for="scn_fld_374">
                <input type="file" id="scn_fld_374" @change="addFile" class="required" name="scn_fld_374"
                  accept="image/jpeg" />
                <div class="file-box__text"><span class="file-box__icon"></span><span>Загрузить документ</span></div>
              </label>
            </div>
          </div>
          <div class="callback__file">
            <p class="callback__file-name">Разворот студенческого билета / <br>
              справка из школы</p>
            <div class="file-box">
              <div class="file-box__file">
                <span></span>
                <div class="clear-form" @click="removeFile">Удалить</div>
              </div>
              <label class="file-box__add" for="scn_fld_415">
                <input type="file" id="scn_fld_415" @change="addFile" class="required" name="scn_fld_415"
                  accept="image/jpeg" />
                <div class="file-box__text"><span class="file-box__icon"></span><span>Загрузить документ</span></div>
              </label>
            </div>
          </div>
          <button class="callback__btn" @click="sendCard">Продолжить</button>
        </div>
        <div class="callback__box" :class="{ active: stateTab == 3 }" v-if="stateTab == 3">
          <p class="callback__title">Уважаемый пользователь карты студента/школьника!</p>
          <div class="callback__text">
            <p>Ваше заявление принято в работу. После проверки данных мы направим подтверждение о продлении срока
              действия
              вашей карты на указанную электронную почту.</p>
          </div>
        </div>
      </div>
    </div>
  </baseLayout>
</template>

<script>
const api = '/service';
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'

import axios from "axios";

import { ru } from 'vuejs-datepicker/dist/locale'

import baseLayout from '@/layout/base'
export default {
  data() {
    return {
      statusError: false, // Статус ошибки
      statusCheckPolicy: false, // Проверка нажатия чекбокса
      year: '', // Возраст
      studDate: '', // срок студенческого 
      language: ru,
      stateTab: 0, // Главные табы
      email: '', // email
      number: '', // Нормер карты
      sendReqStatus: true,
      checkCode: null, // Проверочный код
      schoolClass: null, // Класс
      status: 'fail',
      stud: false,
      checkDateState: false,
      checkClassState: false,
      checkDouble: false,
      errorDouble: false,
      checkCodeValue: null, // Значение проверочного кода
      disabledDates: { // Ограничения дат
        to: new Date(1900, 0, 1),
        from: new Date()
      },
    }
  },
  mounted() {
    this.email = this.$store.getters.profile_date("email-address")
  },
  computed: {},
  methods: {
    focus(e) {
      this.focusInput(e.target);
      e.target.classList.remove('error');
    },
    blur(e) {
      this.blurInput(e.target);
    },
    sendCard() {
      var arr_result = new FormData();
      arr_result.append('fld_380', this.number.split(' ').join(''));
      arr_result.append('date_of_birth', this.moment(this.year).format('DD.MM.YYYY'));
      arr_result.append('fld_424', this.email);
      document.querySelectorAll('.file-box input').forEach((item) => {
        if (item.type == 'file' && item.value == '') {
          this.createError(item.parentElement, 'Файл не добавлен');
          this.statusError = true;
        } else if (item.type == 'file' && item.value != '') {
          arr_result.append(item.name, item.files[0]);
        }
      });
      if (!this.statusError && this.sendReqStatus) {
        this.sendReqStatus = false;
        var url = '/include/form/prolongation.php';
        fetch(url, {
          body: arr_result,
          method: "post"
        }).then((response) => {
          console.log(response)
          if (response.ok) {
            this.stateTab = '3';
            window.scrollTo(0, 0, {
              behavior: 'smooth'
            });
          }
        }).catch((error) => {
          console.log("Ошибка передачи:", error);
        });
      }
    },
    next() {
      var contains = true;
      var form = document.querySelector(`[data-form]`);
      form.querySelectorAll('.required').forEach((item) => { // проверяем все обязательные поля
        if (item.value != undefined && item.value == '') {
          this.createError(item, `Введите ${item.previousElementSibling.textContent}`);
          this.statusError = true;
        }
      });

      contains = [...form.querySelectorAll('.required')].every(function (entry) {
        return !entry.classList.contains('error');
      });

      if (!this.statusCheckPolicy) { // проверяем согласие на персональные данные
        document.querySelector('[type="checkbox"]').classList.add('error');
        this.statusError = true;
        return false;
      }
      if (this.stud) {
        if (form.querySelector('[name="stud"]').classList.contains('error')) { // проверяем согласие на персональные данные
          this.statusError = true;
          return false;
        }
      }
      if (this.statusCheckPolicy && contains && !this.statusError) { // проверяем согласие на персональные данные
        this.stateTab = 2;
      }
    },
    changeCheckbox() {
      this.statusCheckPolicy = !this.statusCheckPolicy
      if (document.querySelector('[type="checkbox"]').classList.contains('error')) {
        document.querySelector('[type="checkbox"]').classList.remove('error');
        this.statusError = false;
      }
    },
    checkDateStud: function (e) {
      var dateInput = document.querySelector('[name="stud"]');
      this.focusInput(dateInput.parentElement.parentElement)
      if (new Date('2024-08-31').getTime() <= new Date(e).getTime()) {
        this.statusError = false;
        this.checkDateState = false;
        dateInput.classList.remove('error');
      } else {
        this.statusError = true;
        this.checkDateState = true;
        dateInput.classList.add('error');
      }
    },
    checkCard(e) {
      if( this.year != ''){
        const dateInput = document.querySelector('[name="date_of_birth"]');
        this.focusInput(dateInput.parentElement.parentElement)
      }
      if( this.number != ''){
        this.blur(e);
      }
      if (this.number != '' && this.year != '') {
        let fullYear = this.moment().diff(this.year, 'years');
        if (fullYear > 14) {
          this.checkClassState = false;
          let year = this.moment(this.year).format('DD.MM.YYYY');
          let number = this.number.split(' ').join('');
          axios.get(api + '/include/studentcard/check.php?c=' + year + ';' + number).then((res) => {
            this.checkDouble = res == 1 ? true : false;
            this.errorDouble = res != 1 ? true : false;
          });
          return
        }
        this.checkClassState = true;
      }
    },
    // Проверка email
    checkInputEmail() {
      const email = document.querySelector('[name=email]');
      var pattern = /^[a-z0-9-_.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{1,6}$/i;
      if (!pattern.test(email.value) && email.value != "") {
        this.createError(email, 'Неккоректный email');
        this.statusError = true;
      } else {
        this.statusError = false;
      }
    },
    //добавление файла
    addFile: function (e) {
      var fileContainer = e.target.parentElement.parentElement;
      var file = e.target.files[0];
      var fileInfo = fileContainer.querySelector('.file-box__file');
      var fileLabel = fileContainer.querySelector('.file-box__add');
      if (fileContainer.querySelectorAll('.error-tooltip').length) {
        fileContainer.querySelector('.error-tooltip').remove()
      }
      if (file.type != 'image/jpeg') {
        this.createError(e.target.parentElement, 'Файл должен быть JPG');
        this.statusError = true;
      } else {
        if (fileContainer.querySelectorAll('.error-tooltip').length) {
          fileContainer.querySelector('.error-tooltip').remove()
        }
        fileInfo.querySelector("span").textContent = file.name;
        fileInfo.style.display = "flex";
        fileLabel.style.display = "none";
        this.statusError = false;
      }
    },
    removeFile: function (e) {
      var fileContainer = e.target.parentElement.parentElement;
      var fileInfo = fileContainer.querySelector('.file-box__file');
      var fileLabel = fileContainer.querySelector('.file-box__add');
      fileInfo.style.display = "none";
      fileLabel.style.display = "block";
      fileLabel.querySelector('input').value = ''
    },
    createError(elem, mess) {
      mess = mess || "Поле обязательное для заполнения";
      var formTooltip = document.createElement("span");
      formTooltip.className = "error-tooltip";
      formTooltip.textContent = mess;
      formTooltip.style.opacity = "1";
      formTooltip.style.transform = "scale(1)";
      if (!elem.parentElement.querySelector('.error-tooltip')) {
        elem.after(formTooltip);
        elem.classList.add('error');
        elem.parentElement.classList.add('error');
      }
    },
  },
  components: {
    baseLayout,
    Multiselect,
    Datepicker
  },
}
</script>