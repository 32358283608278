<template>
  <header class="header">
    <div class="header__inner inner">
      <a href="/" class="header__link">
        <img src="@/assets/icon/logo.svg" alt="Логотип" class="header__pic">
      </a>
      <div class="header__nav" ref="nav">
        <div class="header__nav-box">
          <ul class="header__list">
            <li class="header__item">
              <router-link class="header__link" to="/" @click.native="closeMenu">Главное</router-link>
            </li>
            <li class="header__item">
              <router-link class="header__link" to="/all-service" @click.native="closeMenu">Сервисы</router-link>
            </li>
            <li class="header__item">
              <router-link class="header__link" to="/support" @click.native="closeMenu">Помощь</router-link>
            </li>
          </ul>
          <div class="header__addr">{{ region }}</div>
        </div>
        <div class="header__nav-over" @click="closeMenu"></div>
      </div>
      <div class="header__setting">
        <div class="header__sett-name" @click="openSetting">
          {{ name_first }}
        </div>
        <div class="header__sett-hidden" v-show="open_setting">
          <router-link to="/profile">Профиль</router-link>
          <button @click="open">Выйти</button>
        </div>
        <div class="header__open-nav" @click="openNavMenu">
          <svg v-if="!isMobileNav" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
            <line x1="0.75" y1="1.25" x2="15.25" y2="1.25" stroke="#2196F3" stroke-width="1.5" stroke-linecap="round" />
            <line x1="0.75" y1="7.25" x2="15.25" y2="7.25" stroke="#2196F3" stroke-width="1.5" stroke-linecap="round" />
            <line x1="0.75" y1="13.25" x2="15.25" y2="13.25" stroke="#2196F3" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.842 2.03a.728.728 0 0 0 0-1.047.772.772 0 0 0-1.073 0L6.673 5.956 1.576.983a.772.772 0 0 0-1.073 0 .728.728 0 0 0 0 1.047l5.096 4.974-5.097 4.974a.728.728 0 0 0 0 1.047c.296.29.777.29 1.074 0l5.097-4.974 5.096 4.974c.297.29.777.29 1.074 0a.728.728 0 0 0 0-1.048L7.746 7.004l5.096-4.973Z"
              fill="#2196F3" />
          </svg>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      open_setting: false,
      isMobileNav: null,
    }
  },
  created() {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.header__setting')) {
        this.open_setting = false;
      }
    });
  },
  computed: {
    name_first() {
      const name = this.$store.getters.profile_date("name-first");
      return name.length != 0 ? name : 'Пассажир';
    },
    region() {
      return this.$store.getters.reg_active('name');
    },
  },
  methods: {
    open() {
      this.$parent.$parent.$parent.showPopupExit = true;
    },
    openNavMenu(){
      if(!this.isMobileNav){
        this.open_setting = false;
        this.$refs.nav.style.display = 'block';
        document.querySelector('body').style.overflow = 'hidden';
        this.isMobileNav = true;
        return;
      } else{
        this.closeMenu();
      }
    },
    openSetting(){
      this.open_setting = !this.open_setting;
      if(this.isMobileNav){
        this.closeMenu();
      }
    },
    closeMenu(){
      document.querySelector('body').removeAttribute('style');
      this.isMobileNav = false;
      if(this.$refs.nav != undefined){
        this.$refs.nav.style.display = 'none';
      }
    }
  },
}
</script>
