<template>
  <div class="outer">
    <heading />
    <div class="content">
      <div class="content__container inner">
        <div class="content__contain first">
          <div class="content__head">
            <a href="/support" class="content__back">Помощь</a>
            <h1 class="content__title">Вопросы и ответы</h1>
          </div>
          <div class="content__subtitle">Здесь вы сможете найти ответы на часто задаваемые вопросы о транспортной карте "Ситикард" и безналичной оплате проезда на транспорте.</div>
          <div class="content__faq">
            <div class="faq" v-for="item in faq_list" :key="item.id">
              <div class="faq__head"  @click="openHidden">
                {{ item.title }}
              </div>
              <div class="faq__content" v-html="item.answer">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import heading from '@/components/core/header.vue'
import foot from '@/components/core/footer.vue'

export default {
  name: 'Faq',
  mounted() {
    this.$store.dispatch('get_faq_list');
  },
  computed: {
    ...mapGetters(['faq_list'])
  },
  methods: {
    openHidden(e){
      if(!e.target.classList.contains('active')){
        document.querySelectorAll('.faq').forEach(item => {
          item.querySelector('.faq__head').classList.remove('active');
        })
        e.target.classList.add('active');
        return
      }
      document.querySelectorAll('.faq').forEach(item => {
        item.querySelector('.faq__head').classList.remove('active');
      })
    }
  },
  components: {
    heading,
    foot,
  },
}
</script>
