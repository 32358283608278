<template>
  <baseLayout>
    <div class="content__contain first">
      <div class="content__head">
        <a href="/" class="content__back">Главное</a>
        <h1 class="content__title">Сервисы</h1>
      </div>
      <div class="services-list">
        <div class="services-list__box">
          <div class="services-list__title">Оплаты и проверка баланса карты</div>
          <div class="services-list__items">
            <router-link :to="{ path: 'all-service/balance', query: { service: 'https://card.siticard.ru/52' }}" class="services-list__link">
              <span class="services-list__caption">Узнать баланс <br> и пополнить транспортную карту</span>
              <img src="@/assets/icon/icon-services-list-1.svg" alt="">
            </router-link>
          </div>
        </div>
        <div class="services-list__box">
          <div class="services-list__title">Оформление карт</div>
          <div class="services-list__items">
            <a href="/all-service/apply-for-card/" class="services-list__link" >
              <span class="services-list__caption">Оформить персональную <br> транспортную карту</span>
              <img src="@/assets/icon/icon-services-list-5.svg" alt="">
            </a>
            <a href="/all-service/check" class="services-list__link">
              <span class="services-list__caption">Проверить готовность карты</span>
              <img src="@/assets/icon/icon-services-list-6.svg" alt="">
            </a>
            <a href="/all-service/extend" class="services-list__link">
              <span class="services-list__caption">Продлить карту <br> студента или школьника</span>
              <img src="@/assets/icon/icon-services-list-7.svg" alt="">
            </a>
            <a href="https://siticard.ru/services/apply-for-a-kgno-card/" class="services-list__link" target="_blank">
              <span class="services-list__caption">Оформление карты <br> школьника КЖНО</span>
              <img src="@/assets/icon/icon-services-list-8.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'
export default {
  name: 'Service',
  methods: {
  },
  components: {
    baseLayout,
  },
  watch: {
  },
}
</script>
