<template>
  <baseLayout>
    <div class="content__contain first">
      <div class="content__head">
        <router-link to="/all-service" class="content__back">Сервисы</router-link>
        <h1 class="content__title">Проверка готовности персональной транспортной карты</h1>
      </div>
      <div class="callback">
        <div class="callback__box" :class="{ active: stateTab == 0 }" v-if="stateTab == 0">
          <div class="release">
            <p class="release__text">Для получения информации о готовности заказанной персональной транспортной карты
              введите данные пользователя карты и заполните все поля предлагаемой ниже формы.</p>
            <div class="release__control">
              <button class="btn" @click="stateTab = 1">Продолжить</button>
            </div>
          </div>
        </div>
        <div class="callback__box" :class="{ active: stateTab == 1 }" data-form v-if="stateTab == 1">
          <div class="callback__group" :class="{ error: errorDouble }">
            <span class="callback__name">Номер карты</span>
            <input type="text" class="callback__field required" @focus="focus" @blur="checkSnils" inputmode="numeric"
              v-model='snils' v-mask="'###-###-### ##'">
          </div>
          <div class="callback__group" :class="{ error: errorDouble }">
            <span class="callback__name">Дата рождения</span>
            <datepicker format="dd.MM.yyyy" :language="language" :disabled-dates="disabledDates" v-model='year'
              name="date_of_birth" ref="date_birth" @input="checkCard" autocomplete="off" class="callback__date">
            </datepicker>
          </div>
          <div class="callback__group focus">
            <span class="callback__name">Email</span>
            <input type="text" class="callback__field required" @blur="checkInputEmail" v-model='email'
              inputmode="email" :class="{ error: errorDouble }">
          </div>
          <label class="callback__checkbox">
            <input type="checkbox" @change="changeCheckbox = !changeCheckbox" />
            <span>
              Я подтверждаю, что ознакомился с документом <a href="/user_agreement/" target="_blank">«Пользовательское
                соглашение» </a> и согласен на передачу информации в электронной форме заявления (в том числе
              персональных данных) по открытым каналам связи сети Интернет.
            </span>
          </label>
          <button class="btn" @click="next"
            :disabled="email == '' || number == '' || year == '' || statusError">Продолжить</button>
          <p class="callback__text" v-if="errorDouble">Если вы уверены, что указываете
            верные данные, пожалуйста свяжитесь со службой поддержки в форме связи.</p>
        </div>
        <div class="callback__box" :class="{ active: stateTab == 2 }" v-if="stateTab == 2">
          <p class="callback__text">Файлы документов, указанных в форме, должны быть ТОЛЬКО В ФОРМАТЕ JPEG.
            <br> Размер каждого из файлов не должен превышать 1 Мб.
          </p>
          <div class="callback__file">
            <p class="callback__file-name">Оборотная сторона карты студента / <br>
              школьника с фотографией пользователя</p>
            <div class="file-box">
              <div class="file-box__file">
                <span></span>
                <div class="clear-form" @click="removeFile">Удалить</div>
              </div>
              <label class="file-box__add" for="scn_fld_374">
                <input type="file" id="scn_fld_374" @change="addFile" class="required" name="scn_fld_374"
                  accept="image/jpeg" />
                <div class="file-box__text"><span class="file-box__icon"></span><span>Загрузить документ</span></div>
              </label>
            </div>
          </div>
          <div class="callback__file">
            <p class="callback__file-name">Разворот студенческого билета / <br>
              справка из школы</p>
            <div class="file-box">
              <div class="file-box__file">
                <span></span>
                <div class="clear-form" @click="removeFile">Удалить</div>
              </div>
              <label class="file-box__add" for="scn_fld_415">
                <input type="file" id="scn_fld_415" @change="addFile" class="required" name="scn_fld_415"
                  accept="image/jpeg" />
                <div class="file-box__text"><span class="file-box__icon"></span><span>Загрузить документ</span></div>
              </label>
            </div>
          </div>
          <button class="callback__btn" @click="sendCard">Продолжить</button>
        </div>
        <div class="callback__box" :class="{ active: stateTab == 3 }" v-if="stateTab == 3">
          <p class="callback__title">Уважаемый пользователь карты студента/школьника!</p>
          <div class="callback__text">
            <p>Ваше заявление принято в работу. После проверки данных мы направим подтверждение о продлении срока
              действия
              вашей карты на указанную электронную почту.</p>
          </div>
        </div>
      </div>
    </div>
  </baseLayout>
</template>

<script>
const api = '/service';
import Datepicker from 'vuejs-datepicker';

import axios from "axios";

import { ru } from 'vuejs-datepicker/dist/locale'

import baseLayout from '@/layout/base'
export default {
  data() {
    return {
      statusError: false, // Статус ошибки
      statusCheckPolicy: false, // Проверка нажатия чекбокса
      year: '', // Возраст
      studDate: '', // срок студенческого 
      language: ru,
      stateTab: 0, // Главные табы
      email: '', // email
      number: '', // Нормер карты
      sendReqStatus: true,
      checkCode: null, // Проверочный код
      schoolClass: null, // Класс
      status: 'fail',
      stud: false,
      checkDateState: false,
      checkClassState: false,
      checkDouble: false,
      errorDouble: false,
      checkCodeValue: null, // Значение проверочного кода
      disabledDates: { // Ограничения дат
        to: new Date(1900, 0, 1),
        from: new Date()
      },
    }
  },
  mounted() {
    this.email = this.$store.getters.profile_date("email-address");
  },
  computed: {},
  methods: {
    focus(e) {
      this.focusInput(e.target);
      e.target.classList.remove('error');
    },
    blur(e) {
      this.blurInput(e.target);
    },
    sendCard: function () {
      var contains = true;
      var form = document.querySelector(`[data-form]`);
      if (this.stateTab == 1) {
        var dateInput = form.querySelector('[name="date_of_birth"]') || '';
        form.querySelectorAll('.required').forEach((item) => { // проверяем все обязательные поля
          if (item.value != undefined && item.value == '') {
            this.createError(item, `Введите ${item.previousElementSibling.textContent}`);
            this.statusError = true;
          }
        });
        contains = [...form.querySelectorAll('.required')].every(function (entry) {
          return !entry.classList.contains('error');
        });
        if (dateInput.value == '') { // проверяем дату рождения на заполнение
          if (!dateInput.nextElementSibling) {
            var formTooltip = document.createElement("span");
            formTooltip.className = "error-tooltip";
            formTooltip.textContent = 'Введите дату рождения';
            formTooltip.style.opacity = "1";
            formTooltip.style.transform = "scale(1)";
            dateInput.classList.add('error');
            dateInput.after(formTooltip);
            this.statusError = true;
          }
        }
        if (!this.statusCheckPolicy) { // проверяем согласие на персональные данные
          form.querySelector('[type="checkbox"]').classList.add('error');
          this.statusError = true;
        } else {
          form.querySelector('[type="checkbox"]').classList.remove('error');
          this.statusError = false;
        }
        if (this.email != '' && this.checkCodeValue == null) { // Проверям, введен ли код
          this.createError(form.querySelector('[name="emailcheck"]'), 'Введите код');
          this.statusError = true;
        }

      }
      if (!this.statusError && this.statusCheckPolicy && contains && this.sendReqStatus) {
        this.sendReqStatus = false;
        let year = this.moment(this.year).format('DD.MM.YYYY');
        axios.get(api + '/include/card_done/check-1.php?s=' + this.snils + '&b=' + year + '&e=' + this.email + '&c=' + this.checkCodeValue).then((res) =>{
          const obj = JSON.parse(res);
          this.stateTab = 2;
          if (obj.error == '0') {
            this.result = obj.result;
            this.status = 'success'
          } else {
            this.status = 'fail';
          }
        })
        window.scrollTo(0, 0, {
          behavior: 'smooth'
        });

      }
    },

    dateSeven(date) {
      date = date.split('.');
      var day = new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]));
      day.setDate(day.getDate() + 7);
      return day.toLocaleDateString();
    },
    // Проверка снилс
    checkSnils: function (e) {
      var snils = e.target.value.split('-').join('').split(''); // получаем снилс трасформируем его в строку
      var state = false;
      var checkSum = snils[10] + snils[11]; // суммируем остаток 
      var sum = snils[0] * 9 + snils[1] * 8 + snils[2] * 7 + snils[3] * 6 + snils[4] * 5 + snils[5] * 4 + snils[6] * 3 + snils[7] * 2 + snils[8] * 1;
      if (sum < 100 && sum == checkSum) { // Проверяем на сумму
        state = true;
      } else if ((sum == 100 || sum == 101) && checkSum == 0) {
        state = true;
      } else if (sum > 101 && (sum % 101 == checkSum || sum % 101 == 100 && checkSum == 0)) {
        state = true;
      } else {
        state = false;
      }
      if (e.target.value.length > 0 && !state) { // Проверяем длину введенной строки и сумму чисел снилса
        this.createError(e.target, 'Введите корректный СНИЛС');
        this.statusError = true;
      }
    },
    sendCheckEmail: function () {
      this.checkInputEmail()
      if (!this.statusError) {
        axios.post(`/include/emg.php?email=${this.email.trim()}`).then(() => {
          this.checkCode = 1;
        })
      }
    },
    checkEmail: function (e) {
      axios.post(api + `/include/emc.php?email=${this.email.trim()}&code=${this.checkCodeValue.trim()}`).then((res) => {
        if (document.querySelectorAll('.error-code').length) {
          document.querySelector('.error-code').remove();
        }
        var parent = document.querySelector('.code');
        var formTooltip = document.createElement("span");
        if (res == 1) {
          formTooltip.className = "success-code";
          formTooltip.textContent = "Код верный";
          parent.appendChild(formTooltip);
          e.target.remove();
          this.statusError = false;
        } else {
          formTooltip.className = "error-code";
          formTooltip.textContent = "Код не верен";
          parent.appendChild(formTooltip);
          this.statusError = true;
        }
      })
    },
    // Проверка даты рождения на возраст
    checkDate() {
      var dateInput = document.querySelector('[name="date_of_birth"]');
      if (dateInput.classList.contains('error')) {
        dateInput.classList.remove('error');
        dateInput.nextElementSibling.remove();
      }
    },
    // Проверка email
    checkInputEmail() {
      const email = document.querySelector('[name=email]');
      var pattern = /^[a-z0-9-_.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{1,6}$/i;
      if (!pattern.test(email.value) && email.value != "") {
        this.createError(email, 'Неккоректный email');
        this.statusError = true;
      } else {
        this.statusError = false;
      }
    },
    createError(elem, mess) {
      mess = mess || "Поле обязательное для заполнения";
      var formTooltip = document.createElement("span");
      formTooltip.className = "error-tooltip";
      formTooltip.textContent = mess;
      formTooltip.style.opacity = "1";
      formTooltip.style.transform = "scale(1)";
      if (!elem.parentElement.querySelector('.error-tooltip')) {
        elem.after(formTooltip);
        elem.classList.add('error');
        elem.parentElement.classList.add('error');
      }
    },
  },
  components: {
    baseLayout,
    Datepicker
  },
}
</script>