<template>
  <div class="content content_indent">
    <div class="content__top">
      <div @click="$emit('close')" class="content__backed">
        <svg width="25" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="9" width="25" height="2" rx="1" fill="#90CAF9" />
          <path d="m10 19-9-9 9-9" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <p class="content__title">Сервис: {{series == null ? 'Пополнение' : 'Смена тарифа'}}</p>
    </div>
    <div class="content__box">
      <div class="service-box">
        <div class="service-box__grid-type-1" v-if="!successState">
          <div class="service-box__info">
            <p class="service-box__txt">{{ operation.description }} <br>
              <span :class="{ error: errorMinMax }" v-if="operation.min != operation.max"> Введите сумму от {{ operation.min / 100 }}
                до {{ operation.max / 100 }} рублей</span>
                <br>
              <b v-if="operation.min == operation.max && operation.max > 0">Стоимость: {{ operation.min / 100 }} рублей</b>
            </p>
            <form class="service-box__action" @submit.prevent="get_purchase">
              <div class="service-box__group" v-if="operation.min != operation.max">
                <p class="service-box__name">Сумма оплаты</p>
                <input type="text" class="service-box__field" placeholder="0" v-model="amount" @blur="check_min_max" @keyup="input_number">
              </div>
              <multiselect v-model="paymthd" ref="paymthd" :options="pay_methods" @input="select_paymethod"
                :searchable="false" :show-labels="false" placeholder="Способ оплаты" track-by="id" label="name">
                <template slot="singleLabel" slot-scope="props">
                  <span class="option">
                    <span class="option__title">{{ props.option.name }}</span>
                    <img class="option__image" :src="props.option.pic" :alt="props.option.name">
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option">
                    <span class="option__title">{{ props.option.name }}</span>
                    <span class="option__date">{{ props.option.time }}</span>
                    <img class="option__image" :src="props.option.pic" :alt="props.option.name">
                  </div>
                </template>
              </multiselect>
              <label class="service-box__save-card" v-if="paymthd != null && paymthd.id == 1">
                <input type="checkbox" @change="remember = !remember">
                <span>Запомнить карту</span>
              </label>
              <div class="service-box__control">
                <div class="service-box__cancel btn-outline" @click="$emit('close')">Отмена</div>
                <button class="service-box__pay btn" :disabled="errorPay">Оплатить</button>
                <span class="service-box__error" v-if="operation.series == null && errorMinMax">Ошибка</span>
              </div>
            </form>
          </div>
          <div class="service-box__war">
            <img src="@/assets/icon/icon-warning.svg" alt="" class="service-box__icon">
            <div class="service-box__sign">Оплаченную операцию необходимо записать на транспортную карту</div>
          </div>
        </div>
        <div class="service-box__success" v-if="successState">
          <b class="service-box__caption">Операция выполнена</b>
          <div class="service-box__txt">Записать оплаченную операцию на транспортную карту можно с помощью транспортного
            терминала при поездке на следующий день после оплаты</div>
          <button class="service-box__close btn" @click="$emit('close'), successState = false">Закрыть</button>
        </div>
      </div>
    </div>
    <popup :view="showOpenPay" @close="close_popup_d" :size="'pay'">
      <div class="paybox">
        <iframe :src="payUrl" frameborder="0"
          sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-popups"
          v-if="!isStatusPay"></iframe>
        <div class="paybox__state" v-if="isStatusPay">
          <img src="@/assets/icon/icon-check-success.svg" alt="">
          <p>Успешно оплачено</p>
          <div class="paybox__txt">Записать оплаченную операцию на транспортную карту можно с помощью транспортного
            терминала при поездке на следующий день после оплаты</div>
        </div>
        <div class="paybox__state" v-if="isStatusPay == 100">
          <img src="@/assets/icon/icon-fail.svg" alt="">
          <p>Отмена оплаты</p>
          <button class="btn" @click="close_popup_d">Закрыть</button>
        </div>
        <div class="paybox__state" v-if="isStatusPay == 5">
          <img src="@/assets/icon/icon-fail.svg" alt="">
          <p>Ошибка, повторите попытку</p>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'

import axios from 'axios';
import qs from 'qs';

import popup from '@/layout/popup'

import { mapGetters } from 'vuex'

export default {
  name: 'Refill',
  created() {
    this.$store.dispatch('get_pay_method');

    this.$store.getters.paymethod_list.forEach((item) => {
      this.pay_methods.push({
        id: item.id,
        name: item.name.replace(/(?=(?:.{4})*$)/g, ' ').replace(/X/g, '*'),
        pic: require(`@/assets/icon/icon-${this.det_card_type(item.name)}.svg`)
      })
    })
  },
  computed: {
    ...mapGetters(['card_operations', 'reg_active']),
    operation() {
      return this.$store.getters.card_operation(this.series)
    }
  },
  data() {
    return {
      amount: null,
      paymthd: null,
      pay_methods: [
        {
          id: 0,
          name: 'Система быстрых платежей',
          pic: require('@/assets/icon/icon-sbp.svg')
        },
        {
          id: 1,
          name: 'Новой банковской картой',
          pic: require('@/assets/icon/icon-system-pay.svg')
        },
      ],
      remember: false,
      successState: false,
      errorMinMax: false,
      errorPay: true,
      payUrl: '',
      isStatusPay: 0,
      showOpenPay: false,
    }
  },
  props: ['card', 'series'],
  methods: {
    input_number(e) {
      e.target.value = e.target.value.replace(/[^0-9.]/ig, '');
      if (e.target.value.indexOf(".") != '-1') e.target.value = e.target.value.substring(0, e.target.value.indexOf(".") + 3);
    },
    check_min_max() {
      if (this.amount < this.operation.min / 100 || this.amount > this.operation.max / 100) {
        this.errorMinMax = true;
        this.errorPay = true;
      } else {
        this.errorMinMax = false;
        if (this.paymthd != null) {
          this.errorPay = false
        }
      }
    },
    select_paymethod() {
      if (this.paymthd != null) {
        this.errorPay = false;
        if(this.series == null && this.operation.min != this.operation.max){
          this.check_min_max();
          return
        }
        if(!this.paymthd.id){
          this.remember = false;
        }
      } else {
        this.errorPay = true;
      }
    },
    get_purchase() {
      let id = '';
      if (typeof this.paymthd.id != "number") id = `"id": "${this.paymthd.id}",`;
      if(this.operation.min == this.operation.max) this.amount = this.operation.min;
      const reqbody = {
        action: "cardPurchase",
        region: this.reg_active('id'),
        card: `{"mode":0,"number": "${this.card[0]}","sector": "${this.card[1]}"}`,
        operation: `{"type": ${this.series == null ? 0 : 1},"series":"${this.operation.series}"}`,
        payment: `{${id} "type": ${!this.paymthd.id ? 1 : 0},"remember": ${this.remember ? 1 : 0},"amount":${this.amount * 100},"callback":"${window.location.origin}/status/success-pay"}`
      };

      axios.post(process.env.VUE_APP_API + qs.stringify(reqbody)).then(response => {
        const res = JSON.parse(response.data.operation);
        if (!response.data.status) {
          if (id != '' || res.url == undefined) {
            this.successState = true;
            return
          }
          this.payUrl = res.url;
          this.showOpenPay = true;
          this.pay_listener(res.id);
        }
      });
    },
    pay_listener(id) {
      if(!this.paymthd.id){
        const hasPayStatus = setInterval(() => {
          if (typeof id != "number") return;
          this.$store.dispatch('get_operation_id', id).then((response) => {
            if (!response.status) {
              const res = JSON.parse(response.operation);
              this.isStatusPay = res.status;
              if (this.isStatusPay) clearInterval(hasPayStatus);
            } else {
              this.isStatusPay = 5
            }
          })
        }, 5000);
      }
    },
    close_popup_d() {
      this.showOpenPay = false;
      document.querySelector('body').removeAttribute('style');
      this.isStatusPay = 0;
      this.$store.dispatch('get_card_option', this.card).then(res => {
        const data = JSON.parse(res.data.options)
        if (data.sync) {
          this.$parent.cardSync = true
          this.$parent.numberSync = data.operation.id
          return;
        }
        this.$parent.cardSync = false
      })
      this.$emit('close');
    }
  },

  components: {
    Multiselect,
    popup
  }
}

</script>
