<template>
  <div class="popup" v-if="view">
    <div class="popup__overlay" @click="close"></div>
    <div class="popup__box big">
      <div class="popup__close" @click="close">
        <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1 1 13M13 1 1 13M1 1l12 12M1 1l12 12" stroke="#2196F3" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
      <div class="popup__wrap">
        <form class="callback" @submit.prevent="sendFeedback" v-if="successForm" ref="callback">
          <div class="callback__title">Обращение</div>
          <div class="callback__subtitle">Отправьте в службу поддержки свой вопрос, <br> и мы ответим вам в ближайшее
            время
          </div>
          <multiselect v-model="subject" :options="feedbackList" ref="subject" @select="subject_select"
            :searchable="false" :show-labels="false" placeholder="Тип обращения"></multiselect>
          <div class="callback__group">
            <span class="callback__name">Номер карты</span>
            <input type="text" class="callback__field" v-model="number" @focus="focus" @blur="blur"
              v-mask="'### ### ###'" autocomplete="off">
          </div>
          <div class="callback__group">
            <span class="callback__name">Текст обращения</span>
            <textarea type="text" ref="text" class="callback__field callback__field_area" v-model="text" @focus="focus"
              @blur="blur" autocomplete="off"></textarea>
            <div class="callback__sign">Если вопрос связан с транспортной картой, то укажите ее номер</div>
          </div>
          <label class="callback__file field-file" ref="fileArea">
            <input type="file" class="field-file__hidden" @change="addFile" accept="image/jpeg">
            <div class="field-file__info">
              <span class="field-file__name" ref="fileName">Прикрепить файл</span>
              <div class="field-file__btn field-file__btn_white" @click="removeFile">
                <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="8" width="18" height="2" rx="1" fill="#2196F3" />
                  <rect x="10" width="18" height="2" rx="1" transform="rotate(90 10 0)" fill="#2196F3" />
                </svg>
              </div>
            </div>
          </label>
          <button class="callback__submit btn">Отправить</button>
        </form>
        <div class="callback" v-else>
          <div class="callback__caption">Ваше обращение отправлено</div>
          <div class="callback__subcaption">Мы с вами свяжемся в ближайшее время</div>
          <button class="callback__btn btn" @click="close">Закрыть</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import Multiselect from 'vue-multiselect'

import axios from 'axios';
import { mapState } from 'vuex'

export default {
  props: ['view'],
  data() {
    return {
      number: '',
      text: '',
      successForm: true,
      subject: null,
      sizeForm: 'big',
      img: '',
      errorFeedback: false,
    }
  },
  updated() {
    if (this.view) {
      document.querySelector('body').style.overflow = 'hidden';
    }
  },
  computed: {
    ...mapState(['feedbackList']),
  },
  methods: {
    focus(e) {
      this.focusInput(e.target);
      this.$refs.text.parentElement.classList.remove('error');
      this.errorFeedback = false;
    },
    blur(e) {
      this.blurInput(e.target);
    },
    addFile(e) {
      const file = e.target.files[0];
      this.$refs.fileArea.classList.add('add');
      this.$refs.fileName.textContent = '';
      this.getBase64(window.URL.createObjectURL(file)).then(res => this.img = res);
      this.$refs.fileName.textContent += file.name;
    },
    removeFile(e) {
      if (this.$refs.fileArea.classList.contains('add')) {
        e.stopPropagation();
        e.preventDefault();
        this.$refs.fileArea.querySelector('input').value = '';
        this.$refs.fileArea.classList.remove('add')
        this.$refs.fileName.textContent = 'Прикрепить файл'
      }
    },
    getBase64(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.src = url;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = 800;
          canvas.height = 600;
          ctx.drawImage(img, 0, 0, 800, 600);
          resolve(canvas.toDataURL().replaceAll("data:image/jpg;base64,", ""));
        }
      })
    },
    subject_select() {
      this.errorFeedback = false;
      this.$refs.subject.$refs.tags.classList.remove('error');
    },
    sendFeedback() {
      if (this.subject == null) {
        this.$refs.subject.$refs.tags.classList.add('error');
        this.errorFeedback = true;
        return;
      }
      if (this.text == '') {
        this.$refs.text.parentElement.classList.add('error');
        this.errorFeedback = true;
        return;
      }
      if (!this.errorFeedback) {
        const number = this.number != '' ? `"number":"${this.number.replace(/\s/g, '')}",` : '';
        const img = this.img != '' ? `,"photo":"${this.img}"` : '';
        const reqbody = new FormData();
        reqbody.append("action", "sendFeedback");
        reqbody.append("region", this.$store.getters.reg_active('id'));
        reqbody.append("message", `{"email":"${this.$store.getters.profile_date("email-address")}","subject":"${this.subject}",${number}"text":"${this.text}"${img}}`);
        axios.post(process.env.VUE_APP_API, reqbody).then(response => {
          if (!response.data.status) {
            this.successForm = false;
            this.img = '';
            this.text = '';
            this.number = '';
            this.subject = null;
            this.$refs.callback.reset();
          }
        });
      }
    },
    close() {
      this.number = '';
      this.text = '';
      this.successForm = '';
      this.subject = null;
      this.successForm = true;
      document.querySelector('body').removeAttribute('style');
      this.$emit('close', false)
    }
  },
  components: {
    Multiselect
  },
}
</script>